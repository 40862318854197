import { PASSWORD_SYMBOL_REGEX } from "@/components/activation/Constants";

/**
 * Password.lenientValidateで返されるエラー(lenientValidateで検証される項目)
 */
export type PasswordLenientValidationError = "EmptyError" | "InvalidCharsError";

/**
 * Password.validateで返されるエラー(validateで検証される項目)
 */
export type PasswordValidationError =
  | PasswordLenientValidationError
  | "AlphabetLowerAndUpperError"
  | "DigitError"
  | "LengthError"
  | "SymbolError";

type PasswordValidator = (value: string) => PasswordValidationError | null;

/**
 * ユーザーのパスワードのためのドメインモデル
 * バリデーションなど、パスワードに関する仕様をまとめる
 */
export class Password {
  value: string;

  // validators
  static lengthValidator: PasswordValidator = (password: string) =>
    password.length < 8 || 24 < password.length ? "LengthError" : null;

  static alphabetLowerAndUpperValidator: PasswordValidator = (
    password: string
  ) =>
    !(password.match(/[a-z]/) && password.match(/[A-Z]/))
      ? "AlphabetLowerAndUpperError"
      : null;

  static digitValidator: PasswordValidator = (password: string) =>
    !password.match(/\d/) ? "DigitError" : null;

  static symbolValidator: PasswordValidator = (password: string) =>
    !password.match(PASSWORD_SYMBOL_REGEX) ? "SymbolError" : null;

  static invalidCharsValidator: PasswordValidator = (password: string) =>
    password.replace(PASSWORD_SYMBOL_REGEX, "").replace(/[a-zA-Z\d]/g, "")
      ? "InvalidCharsError"
      : null;

  static emptyValidator: PasswordValidator = (password: string) =>
    password.length === 0 ? "EmptyError" : null;

  static validate(value: string) {
    return [
      Password.lengthValidator,
      Password.alphabetLowerAndUpperValidator,
      Password.digitValidator,
      Password.symbolValidator,
      Password.invalidCharsValidator,
    ]
      .map((validator) => validator(value))
      .filter((e) => e != null) as PasswordValidationError[];
  }

  constructor(value: string) {
    this.value = value;
  }

  /**
   * パスワードを検証する。
   * @returns 検証結果の配列。validatorごとにエラーを格納する。
   */
  validate(): PasswordValidationError[] {
    return Password.validate(this.value);
  }

  /**
   * パスワードを緩く(lenient)検証する。
   * @returns 検証結果の配列。validatorごとにエラーを格納する。
   */
  lenientValidate(): PasswordLenientValidationError[] {
    return [Password.emptyValidator, Password.invalidCharsValidator]
      .map((validator) => validator(this.value))
      .filter((e) => e != null) as PasswordLenientValidationError[];
  }
}
