import { InterviewEntry } from "@/components/examinee/interview/Types";
import { LITE_TARGET_PATH } from "@/sleep_compass_lite/constants";
import { Path } from "@/utils/path";

/** 問診系ページの一覧 */
export type InterviewPages =
  | "DailyInterview"
  | "DailyInterviewCompleted"
  | "DailyInterviewEntrance"
  | "InterviewConnection"
  | "PrimaryInterviewEntrance"
  | "PrimaryInterview"
  | "PrimaryInterviewCompleted"
  | "PrimaryInterviewIntroduction"
  | "DivisionSelect";

/**
 * InterviewPageのパスを取得するために、Pathを拡張したクラス
 * パスとtypeの関連をまとめたutility関数を実装するために定義
 */
export class InterviewPath extends Path<InterviewPages> {
  getPrimaryInterviewPath(
    surveyInfoUid: string,
    entry: InterviewEntry
  ): string {
    return this.getFullPath("PrimaryInterview", {
      ":surveyInfoUid": surveyInfoUid,
      ":interviewId": `${entry.id}`,
      ":screen": `${entry.screen.id}`,
    });
  }

  getPrimaryInterviewIntroductionPath(surveyInfoUid: string): string {
    return this.getFullPath("PrimaryInterviewIntroduction", {
      ":surveyInfoUid": surveyInfoUid,
    });
  }

  getDivisionSelectPath(surveyInfoUid: string): string {
    return this.getFullPath("DivisionSelect", {
      ":surveyInfoUid": surveyInfoUid,
    });
  }

  getDailyInterviewPath(
    surveyInfoUid: string,
    announceId: number,
    entry: InterviewEntry
  ): string {
    return this.getFullPath("DailyInterview", {
      ":surveyInfoUid": surveyInfoUid,
      ":announceId": `${announceId}`,
      ":interviewId": `${entry.id}`,
      ":screen": `${entry.screen.id}`,
    });
  }

  getInterviewConnectionPath(
    surveyInfoUid: string,
    announceId: number
  ): string {
    return this.getFullPath("InterviewConnection", {
      ":surveyInfoUid": surveyInfoUid,
      ":announceId": `${announceId}`,
    });
  }
}

/**
 * 問診系ページのパスクラスのインスタンス
 * 各ページのパスの宣言やパスの取得で、このインスタンスを使用
 * パス変更時のメンテナンス性とコーディング効率(補完やtypo防止)の向上のために作成
 */
export const interviewPath = new InterviewPath(LITE_TARGET_PATH, {
  DailyInterview:
    "/daily_interview/:surveyInfoUid/:announceId/:interviewId/:screen",
  DailyInterviewCompleted:
    "/daily_interview_completed/:surveyInfoUid/:announceId",
  DailyInterviewEntrance:
    "/daily_interview_entrance/:surveyInfoUid/:announceId",
  InterviewConnection: "/interview_connection/:surveyInfoUid/:announceId",
  PrimaryInterviewEntrance: "/primary_interview_entrance/:surveyInfoUid",
  PrimaryInterview: "/primary_interview/:surveyInfoUid/:interviewId/:screen",
  PrimaryInterviewCompleted: "/primary_interview_completed/:surveyInfoUid",
  PrimaryInterviewIntroduction:
    "/primary_interview_introduction/:surveyInfoUid",
  DivisionSelect: "/primary_interview/:surveyInfoUid/division_select",
});
