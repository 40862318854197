import { useAxios } from "@/utils/axios";
import { useMemo } from "react";

export interface PostDivisionResult {
  identifier: string;
  name: string;
}

/**
 * 所属部署を更新するためのAPI。
 * @see https://www.notion.so/accelstars/Lite-URL-API-539c9b6190e3406abfb77288ba1e365d?pvs=4#4510a64c653148c093825abee36de694
 */
export function useDivision() {
  const axios = useAxios();
  const URL = "/api/lite/division/";
  return useMemo(
    () => ({
      async post(identifier: string, surveyInfoUid: string) {
        return axios.post<PostDivisionResult>(URL, {
          identifier,
          survey_info_id: surveyInfoUid,
        });
      },
    }),
    [axios]
  );
}
