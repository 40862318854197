import { EMAIL_ADDRESS_REGEX } from "@/components/activation/Constants";
export type EmailAddressValidationError = "FormatError" | "LengthError";

/**
 * ユーザーのメールアドレスのためのドメインモデル
 * バリデーションなど、メールアドレスに関する仕様をまとめる
 */
export class EmailAddress {
  value: string;

  constructor(value: string) {
    this.value = value;
  }

  /**
   * メールアドレスを検証する
   * @returns 検証結果の配列
   */
  validate(): EmailAddressValidationError[] {
    return EmailAddress.validate(this.value);
  }

  /**
   * メールアドレスを検証する
   *
   * @param value email
   * @returns 検証結果の配列
   */
  static validate(value: string) {
    const errors: EmailAddressValidationError[] = [];
    if (value.length > 254) {
      errors.push("LengthError");
    }
    if (!value.match(EMAIL_ADDRESS_REGEX)) {
      errors.push("FormatError");
    }
    return errors;
  }
}
