import { useAxios } from "@/utils/axios";
import { useMemo } from "react";

export interface Division {
  name: string;
  identifier: string;
  selectable: boolean;
  children: Division[];
}

export interface GetDivisionsResult {
  root: Division | null;
}

/**
 * 部署構造を取得するためのAPI。
 * 部署が存在しなかったり、部署を選択する必要のないSurveyInfoに対して実行した場合などは
 * 404 Not Found が返ってくる。
 * @see https://www.notion.so/accelstars/Lite-URL-API-539c9b6190e3406abfb77288ba1e365d?pvs=4#9e329bb7196249c09bb4e7abde66c57b
 */
export function useDivisions() {
  const axios = useAxios();
  const getURL = (surveyInfoUid: string) =>
    `/api/lite/divisions/${surveyInfoUid}/`;
  return useMemo(
    () => ({
      async get(surveyInfoUid: string) {
        return axios.get<GetDivisionsResult>(getURL(surveyInfoUid));
      },
    }),
    [axios]
  );
}
