import { useAxios } from "@/utils/axios";
import { useMemo } from "react";

export interface GetUserInfoResult {
  last_name: string | null;
  last_name_kana: string | null;
  first_name: string | null;
  first_name_kana: string | null;
  birthday: string | null;
  employee_id: string | null;
  insurance_symbol: string | null;
  insurance_number: string | null;
  email: string | null;
  already_progressed: boolean;
}

export interface PostUserInfoParameter {
  last_name: string | null;
  last_name_kana: string | null;
  first_name: string | null;
  first_name_kana: string | null;
  employee_id: string | null;
  insurance_symbol: string | null;
  insurance_number: string | null;
}

/**
 * 新規にアカウントを作成して測定を開始するためのAPI。
 * RegistrationEntryViewSetを参考に、下記の違いに注意しながら実装する。
 * - 入力値としてSleepCheckupInfo.uuidを必要とせず、代わりにurl_idを用いる
 * - 確認コード入力時（`def update()`）において初めてLiteSurveyInfoを作成する
 * - 姓／名／生年月日／社員番号…等を保存する（既に情報が存在する場合は情報を更新する or バリデーションとして用いる）
 * @see https://www.notion.so/accelstars/Lite-URL-API-539c9b6190e3406abfb77288ba1e365d?pvs=4#89cad8bce0fd46a5854b8a6470f25de1
 */
export function useUserInfo() {
  const axios = useAxios();
  const getURL = (surveyInfoId: string) =>
    `/api/lite/survey_info/${surveyInfoId}/user_info/`;
  return useMemo(
    () => ({
      async get(surveyInfoId: string) {
        return axios.get<GetUserInfoResult>(getURL(surveyInfoId));
      },
      async post(surveyInfoId: string, param: PostUserInfoParameter) {
        return axios.post<void>(getURL(surveyInfoId), param);
      },
    }),
    [axios]
  );
}
