import { LineBreakText } from "@/components/common/LineBreakText";
import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import {
  SlideFromLeftAndFade,
  SlideFromRightAndFade,
} from "@/components/common/SlideAnimation";
import { Copyright } from "@/components/examinee/Copyright";
import { InterviewAppBar } from "@/components/examinee/interview/InterviewAppBar";
import { ProgressBar } from "@/components/examinee/interview/ProgressBar";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import {
  BACKGROUND_COLOR,
  FOOTER_HEIGHT,
} from "@/sleep_compass_lite/components/target/Interview";
import { MX4, PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { TargetAuthenticated } from "@/sleep_compass_lite/components/target/TargetAuthenticated";
import { interviewPath } from "@/sleep_compass_lite/domain_models/target/InterviewPath";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { useDivision } from "@/sleep_compass_lite/use_cases/target/interview/UseDivision";
import {
  Division,
  useDivisions,
} from "@/sleep_compass_lite/use_cases/target/interview/UseDivisions";
import { usePrimaryInterview } from "@/sleep_compass_lite/use_cases/target/interview/UsePrimaryInterview";
import { ArrowBack } from "@mui/icons-material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Backdrop,
  Box,
  Button,
  Container,
  Dialog,
  DialogProps,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

/**
 * 部署選択ページ
 */
export function DivisionSelect({ headerHeight }: ExamineePageProps) {
  const navigate = useNavigate();

  const { surveyInfoUid } = useParams();

  const [divisionPath, setDivisionPath] = useState<Division[]>([]);
  const [fixedDivisionPath, setFixedDivisionPath] = useState<Division[]>([]);
  const [isBack, setIsBack] = useState(false);
  const divisionsService = useDivisions();
  const divisionService = useDivision();

  const [backdropShow, setBackdropShow] = useState(false);
  const [dialogShow, setDialogShow] = useState(false);
  const { getEntry } = usePrimaryInterview();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      if (surveyInfoUid == null) return;
      const res = await divisionsService.get(surveyInfoUid);
      if (res.data.root == null) {
        setDivisionPath([]);
      } else {
        setDivisionPath([res.data.root]);
      }
    })();
  }, [surveyInfoUid, divisionsService]);

  const goBack = () => {
    if (surveyInfoUid == null) {
      throw new Error("surveyInfoUid is null");
    }
    const primaryInterviewIntroductionPath =
      interviewPath.getPrimaryInterviewIntroductionPath(surveyInfoUid);
    navigate(primaryInterviewIntroductionPath);
  };

  const startPrimaryInterview = async () => {
    try {
      if (surveyInfoUid == null) {
        throw new Error("surveyInfoUid is null");
      }

      // 所属部署を更新する
      const lastDivision = fixedDivisionPath[fixedDivisionPath.length - 1];
      await divisionService.post(lastDivision.identifier, surveyInfoUid);

      // 事前問診の最初の画面に遷移する
      const entry = await getEntry(surveyInfoUid);
      const primaryInterviewPath = interviewPath.getPrimaryInterviewPath(
        surveyInfoUid,
        entry
      );
      navigate(primaryInterviewPath, {
        // 問診画面から戻るために、現在の pathname を渡して遷移
        state: {
          prevPathname: window.location.pathname,
        },
      });
    } catch (e: unknown) {
      setErrorMessage(getErrorMessage(e));
      // エラーアラートが画面内に表示されるようにスクロールをリセットする
      window.scrollTo(0, 0);
    }
  };

  const handleSelectStartClick = () => {
    setBackdropShow(true);
    setDialogShow(true);
  };

  const handleClickNext = () => {
    startPrimaryInterview();
  };

  return (
    <TargetAuthenticated>
      <ScrollToTop />
      <Box
        sx={{
          minHeight: "100vh",
          position: "relative",
          backgroundColor: "background.blankSpace",
        }}
      >
        <InterviewAppBar
          height={headerHeight}
          title="組織情報"
          onClickBack={goBack}
        />
        <Container
          maxWidth="sm"
          sx={{
            p: 0,
            pt: 0,
            pl: { xs: 0, sm: 0 },
            pr: { xs: 0, sm: 0 },
            pb: FOOTER_HEIGHT,
            backgroundColor: BACKGROUND_COLOR,
            minHeight: `calc(100vh - ${headerHeight}px - ${FOOTER_HEIGHT})`,
          }}
        >
          {errorMessage && (
            <Stack
              sx={{
                pt: 8,
                px: PX4,
                backgroundColor: "white",
              }}
            >
              <NonFieldError>{errorMessage}</NonFieldError>
            </Stack>
          )}
          <Stack>
            <QuestionPart />
            <Backdrop open={backdropShow} sx={{ zIndex: 10000 }} />
            <Stack sx={{ backgroundColor: BACKGROUND_COLOR, mx: MX4, my: 6 }}>
              {0 === fixedDivisionPath.length && (
                // まだ選択された部署が無い場合は選択開始ボタンを表示する
                <SelectStartButton
                  onClick={handleSelectStartClick}
                  disabled={divisionPath.length === 0}
                />
              )}
              {0 < fixedDivisionPath.length && (
                // 既に選択された部署がある場合は、選択された部署のパスと修正ボタンを表示する
                <FixedDivision
                  fixedDivisionPath={fixedDivisionPath}
                  onClickEdit={handleSelectStartClick}
                />
              )}

              <Button
                variant="contained"
                disabled={fixedDivisionPath.length === 0}
                onClick={handleClickNext}
                sx={{ mt: 13 }}
              >
                次へ
              </Button>

              <DivisionSelectDialog
                divisionPath={divisionPath}
                onChangeDivisionPath={(divisions: Division[], isBack) => {
                  setDialogShow(false);
                  setTimeout(() => {
                    // 部署を1階層分選択したときの処理
                    setIsBack(isBack);
                    setDivisionPath(divisions);
                    setDialogShow(true);
                  }, 300);
                }}
                onFinish={(division: Division) => {
                  // 所属する部署を決定したときの処理
                  const lastDivision = divisionPath[divisionPath.length - 1];
                  if (lastDivision.identifier !== division.identifier) {
                    // children が存在するのに直下を選んだ場合
                    setFixedDivisionPath([...divisionPath, division]);
                  } else {
                    // children が存在しない部署（＝葉ノード）を選んだ場合
                    setFixedDivisionPath([...divisionPath]);
                  }
                  setDialogShow(false);
                  setBackdropShow(false);
                }}
                dialogProps={{
                  sx: {
                    zIndex: 10001,
                  },
                  TransitionComponent: isBack
                    ? SlideFromLeftAndFade
                    : SlideFromRightAndFade,
                  open: dialogShow,
                  onClose: (event, reason) => {
                    if (reason === "backdropClick") {
                      return;
                    }
                    setDialogShow(false);
                    setBackdropShow(false);
                  },
                  BackdropProps: {
                    invisible: true,
                  },
                  TransitionProps: {
                    timeout: {
                      enter: 300,
                      exit: 200,
                    },
                    onExited: () => {
                      // ダイアログが閉じるアニメーション完了後に初期化する
                      // （そうしないとダイアログが閉じる前に見た目が変わってしまうため）
                      setDivisionPath([divisionPath[0]]);
                    },
                  },
                }}
              />
            </Stack>
          </Stack>
        </Container>
        <Stack alignItems="center">
          <Stack
            alignItems="center"
            justifyContent="center"
            maxWidth="sm"
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: FOOTER_HEIGHT,
              backgroundColor: BACKGROUND_COLOR,
            }}
          >
            <Copyright />
          </Stack>
        </Stack>
      </Box>
    </TargetAuthenticated>
  );
}

function QuestionPart() {
  return (
    <Box sx={{ backgroundColor: "white" }}>
      <Stack spacing={6} sx={{ mx: MX4, my: 6 }}>
        <ProgressBar value={2} />
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Typography variant="subtitle1">
              あなたの所属組織を選択してください。
            </Typography>
            <Typography variant="caption">
              <LineBreakText text="兼務の場合は、主務を選択してください。" />
              <br />
              <LineBreakText text="出向している場合は、出向先を選択してください。" />
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

function SelectStartButton({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) {
  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      sx={{
        backgroundColor: disabled ? "#E0E0E0" : "white",
        border: disabled ? "1px solid #E0E0E0" : "1px solid #0000003B",
        borderRadius: 2,
        cursor: "pointer",
        px: 3,
        py: 4,
      }}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <Typography
        sx={{
          color: disabled ? "rgba(0, 0, 0, 0.26)" : "text.secondary",
          fontSize: "16px",
        }}
      >
        選択してください
      </Typography>
      <Arrow disabled={disabled} />
    </Stack>
  );
}

function FixedDivision({
  fixedDivisionPath,
  onClickEdit,
}: {
  fixedDivisionPath: Division[];
  onClickEdit: () => void;
}) {
  // ルートが表すのは「全社」のような特別な部署であるため、表示しない。
  // ただし、ルート直下が選択された場合は表示する。
  const divisionPathForShow =
    fixedDivisionPath.length === 1
      ? fixedDivisionPath
      : fixedDivisionPath.slice(1);

  return (
    <>
      <Stack
        spacing={1}
        sx={{
          backgroundColor: "white",
          border: "1px solid #E0E0E0",
          borderRadius: "8px",
          p: 4,
        }}
      >
        {divisionPathForShow.map((division, idx) => (
          <Typography key={idx} variant="subtitle2">
            {division.name}
          </Typography>
        ))}
      </Stack>
      <Stack flexDirection="row" justifyContent="space-around" sx={{ mt: 5 }}>
        <Button
          startIcon={<EditIcon />}
          variant="text"
          size="small"
          onClick={onClickEdit}
        >
          修正する
        </Button>
      </Stack>
    </>
  );
}

function DivisionSelectDialog({
  divisionPath,
  onChangeDivisionPath,
  onFinish,
  dialogProps,
}: {
  divisionPath: Division[];
  onChangeDivisionPath: (divisions: Division[], isBack: boolean) => void;
  onFinish: (division: Division) => void;
  dialogProps: DialogProps;
}) {
  const lastDivision =
    0 < divisionPath.length ? divisionPath[divisionPath.length - 1] : null;

  function ListItem({
    division,
    onClick,
    caption,
    noBorder,
  }: {
    division: Division;
    onClick: () => void;
    caption?: string;
    noBorder?: boolean;
  }) {
    return (
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          borderBottom: noBorder ? "none" : "1px solid #E0E0E0",
          cursor: "pointer",
          p: "15px 16px",
        }}
        onClick={onClick}
      >
        <Typography variant="body2">{caption ?? division.name}</Typography>
        <Arrow />
      </Stack>
    );
  }

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "8px",
        },
      }}
      fullWidth
      maxWidth="sm"
      {...dialogProps}
    >
      <Stack
        flexDirection="column"
        onClick={(e) => {
          // ダイアログの内側をクリックすると、ダイアログの親要素がクリックされたことになるため、
          // それを避けるためにstopPropagationしておく。
          e.stopPropagation();
        }}
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            borderBottom: "1px solid #E0E0E0",
            p: "14.5px 16px",
          }}
        >
          {1 === divisionPath.length && (
            <Typography variant="subtitle2" sx={{ color: "#707070" }}>
              組織を選択
            </Typography>
          )}
          {1 < divisionPath.length && (
            <Stack>
              <Button
                startIcon={<ArrowBack sx={{ fontSize: "13px" }} />}
                variant="text"
                size="small"
                onClick={() =>
                  onChangeDivisionPath(divisionPath.slice(0, -1), true)
                }
                sx={{
                  p: 0,
                }}
              >
                戻る
              </Button>
            </Stack>
          )}
          <IconButton
            aria-label="close"
            onClick={() => {
              dialogProps.onClose?.({}, "escapeKeyDown");
            }}
            sx={{ p: 0 }}
          >
            <CloseIcon sx={{ color: "#707070" }} />
          </IconButton>
        </Stack>
        <Paper
          style={{
            maxHeight: "calc(85vh - 60px)", // 60px はダイアログタイトル部分のおおよその高さ
            overflow: "auto",
          }}
        >
          {lastDivision != null &&
            lastDivision.children.map((child, idx) => (
              <ListItem
                key={idx}
                division={child}
                onClick={() => {
                  if (child.children.length === 0 && child.selectable) {
                    onFinish(child);
                  } else {
                    onChangeDivisionPath([...divisionPath, child], false);
                  }
                }}
              />
            ))}

          {/* もしその部署直下を選択可能な場合は直下用の選択肢を追加する */}
          {lastDivision != null && lastDivision.selectable && (
            <ListItem
              division={lastDivision}
              onClick={() => onFinish(lastDivision)}
              caption={`指定なし（${lastDivision.name}直下）`}
              noBorder
            />
          )}
        </Paper>
      </Stack>
    </Dialog>
  );
}

function Arrow({ disabled }: { disabled?: boolean }) {
  return (
    <ArrowForwardIosSharpIcon
      sx={{
        color: disabled ? "rgba(0, 0, 0, 0.26)" : "#0000008A",
        fontSize: "16px",
      }}
    />
  );
}
