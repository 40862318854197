/**
 * Objectのkeyとvalueを返却します。
 * {@link Object.entries} では戻り値の型に[string, any][]を指定しているため、引数で指定したObjectのkeyの型がstringになってしまうため、それを解決するために[keyof T, T[keyof T]][]を指定しています。
 * @param o
 * @returns
 */
export function entries<T extends Record<string, any>>(
  o: T
): [keyof T, T[keyof T]][] {
  return Object.entries(o);
}

/**
 * type predicate を使用して引数の型を推論します。
 *
 * この関数は、引数に指定した値が null または undefined ではなかった場合に引数の値が null および undefined 以外の型(T) として推論します。
 *
 * ```
 * function getValue(): string | null | undefined {
 *    let x: string | null | undefined;
 *    // (なにがしかの処理)
 *    return x
 * }
 *
 * const value = getValue();  // この時点では value は string | null | undefined として推論される
 * if (isNotNullish(value)) {
 *    value; // null または undefined ではないため string型 と推論される
 * } else {
 *    value; // null | undefined と推論される
 * }
 * ```
 * この関数は主に以下のように null と null 以外の値が混在するような配列に対し、filter や find 操作を行う際に使用することを想定しています。
 *
 * ```
 * function getArray(): (string | null | undefined)[] {
 *   return ["", null, undefined];
 * }
 * const a = getArray().filter(isNotNullish);  // a が string[] に推論される
 * const b = getArray().filter((x) => x != null);  // type predicate を使用しない場合、`x != null`  とfilter しているにもかかわらず b が (string | null | undefined)[] に推論される
 * ```
 *
 * 単純に値が null または undefined かどうかを検査したい場合は、`!isNotNullish`のような形でこの関数は使用せずに `value == null` の使用を検討してください。
 *
 * @param value 検査対象
 * @returns 引数が null または undefined ではない場合 true
 */
export function isNotNullish<T>(value: T | null | undefined): value is T {
  return value != null;
}
