import { useScreenLock } from "@/components/ScreenLock";
import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { GeneralError } from "@/components/sleep_checkup_v1/GeneralError";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import { PasswordCheckListItem } from "@/sleep_compass_lite/components/target/PasswordCheckListItem";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import {
  URLInformation,
  useURLInformation,
} from "@/sleep_compass_lite/components/target/greetings/hooks";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import {
  EmailAddress,
  EmailAddressValidationError,
} from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import {
  Password,
  PasswordValidationError,
} from "@/sleep_compass_lite/domain_models/target/Password";
import {
  validateEmployeeNo,
  validateFirstName,
  validateFirstNameKana,
  validateInsuranceNumber,
  validateInsuranceSymbol,
  validateLastName,
  validateLastNameKana,
} from "@/sleep_compass_lite/domain_models/target/authentication/validators";
import { StateType as ConfirmationStateType } from "@/sleep_compass_lite/pages/target/authentication/Confirmation";
import {
  getEmailErrorMessages,
  getErrorMessage,
  getPasswordErrorMessages,
} from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import {
  signUp,
  useURLRegistrationEntry,
} from "@/sleep_compass_lite/use_cases/target/authentication/SignUp";
import { validateBirthday } from "@/utils/date";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const EMAIL_VALIDATION_ERROR_MESSAGES: ReadonlyMap<
  EmailAddressValidationError,
  string
> = new Map([
  ["FormatError", "メールアドレスの形式が正しくありません"],
  ["LengthError", "メールアドレスは254文字以下にしてください"],
]);
const PASSWORD_CHECK_ITEMS: ReadonlyMap<PasswordValidationError, string> =
  new Map([
    ["LengthError", "8文字以上24文字以下にしてください"],
    ["AlphabetLowerAndUpperError", "大文字と小文字の半角英字を含めてください"],
    ["DigitError", "半角数字を含めてください"],
    ["SymbolError", "記号を含めてください"],
  ]);

// このページが受け取るstateの型
export type StateType = {
  surveyInfo: string;
};

/**
 * サインアップページ
 */
export function SignUp() {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState<EmailAddress>(new EmailAddress(""));
  const [password, setPassword] = useState<Password>(new Password(""));
  const [errorMessage, setErrorMessage] = useState("");
  const [emailErrorMessages, setEmailErrorMessages] = useState<string[]>([]);
  const [passwordErrorMessages, setPasswordErrorMessages] = useState<string[]>(
    []
  );
  const [isLocked, setLock] = useScreenLock();

  const { surveyInfo } = (location.state as StateType) || {};
  const passwordValidationErrors = password.validate();
  const emailValidationErrors = email.validate();
  const hasErrors =
    passwordValidationErrors.length !== 0 || emailValidationErrors.length !== 0;

  const handleClickRegister = async () => {
    if (isLocked || hasErrors || surveyInfo == null) {
      // 以下のケースでは、登録処理を行わない
      // - 画面ロック中
      // - 何かしらのエラーがある
      return;
    }

    setLock(true);

    try {
      const registrationEntry = await signUp(surveyInfo, email, password);

      navigate(authenticationPath.getFullPath("Confirmation"), {
        state: {
          email: email,
          password: password,
          registrationEntryId: registrationEntry.id,
          surveyInfo: registrationEntry.survey_info,
        } as ConfirmationStateType,
      });
    } catch (e: unknown) {
      const errorMessageFromApi = getErrorMessage(e, "");
      if (errorMessageFromApi) {
        setErrorMessage(errorMessageFromApi);
      }
      const emailErrors = getEmailErrorMessages(e);
      if (emailErrors) {
        setEmailErrorMessages(emailErrors);
      }
      const passwordErrors = getPasswordErrorMessages(e);
      if (passwordErrors) {
        setPasswordErrorMessages(passwordErrors);
      }

      // エラーアラートが画面内に表示されるようにスクロールをリセットする
      window.scrollTo(0, 0);
    } finally {
      setLock(false);
    }
  };

  if (!surveyInfo) {
    // 遷移先がないエラーメッセージ画面を表示する。本来なら、やり直すために、Greetings画面に遷移させたいが、surveyInfoがなく、Greetings画面のURLが分からないので、遷移先をなしにする。
    return <GeneralError to="" />;
  }

  return (
    <>
      <ScrollToTop />
      <Stack spacing={12} sx={{ pt: 8, pb: 16, px: PX4 }}>
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            新規アカウント登録
          </Typography>
          {/* エラーメッセージ */}
          <NonFieldError>{errorMessage}</NonFieldError>
          <Typography
            variant="subtitle2"
            textAlign="justify"
            sx={{ color: "text.secondary" }}
          >
            以下のフォームに登録確認用メールを受信できるメールアドレス、ログイン時に使用するパスワードを入力し、[登録する]ボタンを押してください。
          </Typography>
          <TextForm
            title="メールアドレス"
            placeholder="例：sleep-compass@example.com"
            value={email.value}
            onChange={(e) => {
              setEmail(new EmailAddress(e.target.value));
            }}
            validator={() =>
              emailValidationErrors.map(
                (e) => `※${EMAIL_VALIDATION_ERROR_MESSAGES.get(e)}`
              )
            }
            givenErrors={emailErrorMessages}
          />
          <TextForm
            title="新しいパスワード"
            value={password.value}
            onChange={(e) => {
              setPassword(new Password(e.target.value));
            }}
            type="password"
            helperText={
              <Stack sx={{ px: 3 }}>
                {[...PASSWORD_CHECK_ITEMS].map(([k, v]) => (
                  <PasswordCheckListItem
                    key={k}
                    text={v}
                    checked={!passwordValidationErrors.includes(k)}
                  />
                ))}
              </Stack>
            }
            givenErrors={
              passwordValidationErrors.includes("InvalidCharsError")
                ? ["※使用できない文字が含まれています"]
                : passwordErrorMessages
            }
          />
        </Stack>
        <Button
          variant="contained"
          size="large"
          sx={{ boxShadow: "none" }}
          onClick={handleClickRegister}
          disabled={hasErrors || isLocked}
        >
          登録する
        </Button>
      </Stack>
    </>
  );
}

function toTop<R>(callback: () => R) {
  const result = callback();
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  return result;
}

interface URLSignUpState {
  urlId?: string;
}

export function URLSignUp() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLocked, setLock] = useScreenLock();

  const urlInformationService = useURLInformation();
  const urlRegistrationEntryService = useURLRegistrationEntry();

  const { urlId } = (location.state ?? {}) as URLSignUpState;

  const [isConfirm, setConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [urlInformation, setURLInformation] = useState<URLInformation>();
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState<string[]>();
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState<string[]>();

  const [lastNameKana, setLastNameKana] = useState("");
  const [lastNameKanaError, setLastNameKanaError] = useState<string[]>();

  const [firstNameKana, setFirstNameKana] = useState("");
  const [firstNameKanaError, setFirstNameKanaError] = useState<string[]>();

  const [birthday, setBirthday] = useState("");
  const [birthdayError, setBirthdayError] = useState<string[]>();
  const [employeeNo, setEmployeeNo] = useState("");
  const [employeeNoError, setEmployeeNoError] = useState<string[]>();

  const [email, setEmail] = useState("");
  const [emailErrorTypes, setEmailErrorTypes] =
    useState<EmailAddressValidationError[]>();

  const [password, setPassword] = useState("");
  const [passwordErrorTypes, setPasswordErrorTypes] =
    useState<PasswordValidationError[]>();

  const [insuranceSymbol, setInsuranceSymbol] = useState("");
  const [insuranceSymbolError, setInsuranceSymbolError] = useState<string[]>();

  const [insuranceNumber, setInsuranceNumber] = useState("");
  const [insuranceNumberError, setInsuranceNumberError] = useState<string[]>();

  const register = useCallback(async () => {
    if (isLocked || urlId == null) return;
    setLock(true);
    try {
      const res = await urlRegistrationEntryService.post({
        email,
        password,
        url_id: urlId,
      });
      navigate(authenticationPath.getFullPath("Confirmation"), {
        state: {
          urlId,
          registrationEntryId: res.data.id,
          lastName,
          firstName,
          lastNameKana,
          firstNameKana,
          birthday,
          employeeNo,
          email,
          password,
          insuranceSymbol,
          insuranceNumber,
        },
      });
    } catch (e) {
      const errorMessage = getErrorMessage(e, "");
      if (errorMessage) {
        setErrorMessage(errorMessage);
      }
      setLock(false);
      window.scrollTo(0, 0);
    }
  }, [
    navigate,
    isLocked,
    setLock,
    urlRegistrationEntryService,
    urlId,
    lastName,
    firstName,
    lastNameKana,
    firstNameKana,
    birthday,
    employeeNo,
    email,
    password,
    insuranceSymbol,
    insuranceNumber,
  ]);

  useEffect(() => {
    (async () => {
      if (urlId == null) return null;
      const res = await urlInformationService.get(urlId);
      setURLInformation(res.data);
    })();
  }, [urlId, urlInformationService]);

  const isDisabled = useCallback(() => {
    return [
      lastNameError,
      firstNameError,
      lastNameKanaError,
      firstNameKanaError,
      birthdayError,
      urlInformation?.id_type === "EMPLOYEE_ID" ? employeeNoError : [],
      emailErrorTypes,
      passwordErrorTypes,
      urlInformation?.id_type === "INSURANCE_CARD" ? insuranceSymbolError : [],
      urlInformation?.id_type === "INSURANCE_CARD" ? insuranceNumberError : [],
    ].some((v) => v == null || v.length > 0);
  }, [
    urlInformation,
    lastNameError,
    firstNameError,
    lastNameKanaError,
    firstNameKanaError,
    birthdayError,
    employeeNoError,
    emailErrorTypes,
    passwordErrorTypes,
    insuranceSymbolError,
    insuranceNumberError,
  ]);

  if (urlId == null) {
    // 遷移先がないエラーメッセージ画面を表示する。本来なら、やり直すために、Greetings画面に遷移させたいが、urlIdがなく、Greetings画面のURLが分からないので、遷移先をなしにする。
    return <GeneralError to="" />;
  }
  return (
    <>
      <ScrollToTop />
      <Stack spacing={12} sx={{ pt: 8, pb: 16, px: PX4 }}>
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            {isConfirm ? "登録内容を確認" : "新規アカウント登録"}
          </Typography>
          <NonFieldError>{errorMessage}</NonFieldError>
          <Typography
            variant="subtitle2"
            textAlign="justify"
            sx={{ color: "text.secondary" }}
          >
            {isConfirm
              ? "以下の内容で登録します。内容に誤りがないか、ご確認ください。"
              : "以下のフォームに必要事項を入力し、［登録内容の確認に進む］ボタンを押してください。"}
          </Typography>
          <Stack spacing={1}>
            <Typography variant="subtitle2">
              {isConfirm ? "氏名" : "お名前"}
            </Typography>
            {isConfirm ? (
              <Stack spacing={6}>
                <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                  {lastName} {firstName}
                </Typography>
                <Divider />
              </Stack>
            ) : (
              <Stack direction="row" spacing={2}>
                <TextForm
                  placeholder="姓"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  validator={() =>
                    validateLastName("姓", lastName, setLastNameError)
                  }
                ></TextForm>
                <TextForm
                  placeholder="名"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  validator={() =>
                    validateFirstName("名", firstName, setFirstNameError)
                  }
                ></TextForm>
              </Stack>
            )}
          </Stack>
          <Stack spacing={1}>
            <Typography variant="subtitle2">
              {isConfirm ? "フリガナ" : "お名前（フリガナ）"}
            </Typography>
            {isConfirm ? (
              <Stack spacing={6}>
                <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                  {lastNameKana} {firstNameKana}
                </Typography>
                <Divider />
              </Stack>
            ) : (
              <Stack direction="row" spacing={2}>
                <TextForm
                  placeholder="セイ"
                  value={lastNameKana}
                  onChange={(e) => setLastNameKana(e.target.value)}
                  validator={() =>
                    validateLastNameKana(
                      "セイ",
                      lastNameKana,
                      setLastNameKanaError
                    )
                  }
                ></TextForm>
                <TextForm
                  placeholder="メイ"
                  value={firstNameKana}
                  onChange={(e) => setFirstNameKana(e.target.value)}
                  validator={() =>
                    validateFirstNameKana(
                      "メイ",
                      firstNameKana,
                      setFirstNameKanaError
                    )
                  }
                ></TextForm>
              </Stack>
            )}
          </Stack>
          <Stack spacing={1}>
            <Typography variant="subtitle2">生年月日</Typography>
            {isConfirm ? (
              <Stack spacing={6}>
                <Typography variant="body1">
                  {birthday.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1年$2月$3日")}
                </Typography>
                <Divider />
              </Stack>
            ) : (
              <TextForm
                placeholder="例：20230301"
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
                validator={() => {
                  const error = validateBirthday(birthday);
                  setBirthdayError(error ?? []);
                  return error;
                }}
              ></TextForm>
            )}
          </Stack>
          {urlInformation?.id_type === "EMPLOYEE_ID" && (
            <Stack spacing={1}>
              <Typography variant="subtitle2">社員番号</Typography>
              {isConfirm ? (
                <Stack spacing={6}>
                  <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                    {employeeNo}
                  </Typography>
                  <Divider />
                </Stack>
              ) : (
                <TextForm
                  placeholder="例：abc0123"
                  value={employeeNo}
                  onChange={(e) => setEmployeeNo(e.target.value)}
                  validator={() =>
                    validateEmployeeNo(
                      "社員番号",
                      employeeNo,
                      setEmployeeNoError
                    )
                  }
                ></TextForm>
              )}
            </Stack>
          )}
          {urlInformation?.id_type === "INSURANCE_CARD" && (
            <Stack spacing={1}>
              <Typography variant="subtitle2">保険証の記号・番号</Typography>
              {isConfirm ? (
                <Stack spacing={6}>
                  <Stack spacing={1}>
                    <Typography variant="body1">
                      <Typography component="span" color="textSecondary">
                        記号:
                      </Typography>
                      <Typography
                        component="span"
                        sx={{ marginLeft: 2, wordBreak: "break-all" }}
                      >
                        {insuranceSymbol}
                      </Typography>
                    </Typography>
                    <Typography variant="body1">
                      <Typography component="span" color="textSecondary">
                        番号:
                      </Typography>
                      <Typography
                        component="span"
                        sx={{ marginLeft: 2, wordBreak: "break-all" }}
                      >
                        {insuranceNumber}
                      </Typography>
                    </Typography>
                  </Stack>
                  <Divider />
                </Stack>
              ) : (
                <Stack spacing={4}>
                  <Stack direction="row" spacing={2}>
                    <TextForm
                      placeholder="記号"
                      value={insuranceSymbol}
                      onChange={(e) => setInsuranceSymbol(e.target.value)}
                      validator={() =>
                        validateInsuranceSymbol(
                          "記号",
                          insuranceSymbol,
                          setInsuranceSymbolError
                        )
                      }
                    ></TextForm>
                    <TextForm
                      placeholder="番号"
                      value={insuranceNumber}
                      onChange={(e) => setInsuranceNumber(e.target.value)}
                      validator={() =>
                        validateInsuranceNumber(
                          "番号",
                          insuranceNumber,
                          setInsuranceNumberError
                        )
                      }
                    ></TextForm>
                  </Stack>
                  <Stack direction="row" sx={{ backgroundColor: "#F6F5F4" }}>
                    <Stack
                      component="span"
                      sx={{
                        marginX: "auto",
                        marginY: 3,
                      }}
                    >
                      <img
                        src="/img/image_lite_insurance_card.png"
                        alt="Insurance Card"
                        loading="lazy"
                      />
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>
          )}
          <Stack spacing={1}>
            <Typography variant="subtitle2">メールアドレス</Typography>
            {isConfirm ? (
              <Stack spacing={6}>
                <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                  {email}
                </Typography>
                <Divider />
              </Stack>
            ) : (
              <TextForm
                placeholder="例：sleep-compass@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                validator={() => {
                  const errors = EmailAddress.validate(email);
                  setEmailErrorTypes(errors);
                  return errors.map(
                    (k) => EMAIL_VALIDATION_ERROR_MESSAGES.get(k) ?? ""
                  );
                }}
              ></TextForm>
            )}
          </Stack>
          <Stack spacing={1}>
            <Typography variant="subtitle2">新しいパスワード</Typography>
            {isConfirm ? (
              <Typography variant="body1">●●●●●●●●</Typography>
            ) : (
              <TextForm
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordErrorTypes(Password.validate(e.target.value));
                }}
                helperText={
                  <Stack sx={{ px: 3 }}>
                    {[...PASSWORD_CHECK_ITEMS].map(([k, v]) => (
                      <PasswordCheckListItem
                        key={k}
                        text={v}
                        checked={
                          passwordErrorTypes == null
                            ? false
                            : !passwordErrorTypes.includes(k)
                        }
                      />
                    ))}
                  </Stack>
                }
              ></TextForm>
            )}
          </Stack>
        </Stack>
        {isConfirm ? (
          <Stack spacing={4}>
            <Button
              variant="contained"
              size="large"
              sx={{
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
              onClick={() => {
                register();
              }}
            >
              登録する
            </Button>
            <Button
              variant="text"
              size="large"
              sx={{
                boxShadow: "none",
                color: "text.secondary",
              }}
              onClick={() =>
                toTop(() => {
                  setErrorMessage("");
                  setConfirm(false);
                })
              }
            >
              <Typography
                variant="body2"
                sx={{ textDecoration: "underline #c6c6c6" }}
              >
                内容を修正する
              </Typography>
            </Button>
          </Stack>
        ) : (
          <Stack>
            <Button
              variant="contained"
              size="large"
              sx={{
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
              disabled={isDisabled()}
              onClick={() => toTop(() => setConfirm(true))}
            >
              登録内容の確認に進む
            </Button>
          </Stack>
        )}
      </Stack>
    </>
  );
}
