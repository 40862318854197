import { useScreenLock } from "@/components/ScreenLock";
import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { GeneralError } from "@/components/sleep_checkup_v1/GeneralError";
import { SCSnackbar } from "@/components/sleep_checkup_v1/SCSnackbar";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import { EmailAddress } from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import {
  EmailVerificationCode,
  EmailVerificationCodeValidationError,
} from "@/sleep_compass_lite/domain_models/target/EmailVerificationCode";
import { Password } from "@/sleep_compass_lite/domain_models/target/Password";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { registration } from "@/sleep_compass_lite/use_cases/target/authentication/Registration";
import { resendEmailVerificationCode } from "@/sleep_compass_lite/use_cases/target/authentication/ResendEmailVerificationCode";
import {
  URLRegistrationEntryPutParameter,
  useURLRegistrationEntry,
} from "@/sleep_compass_lite/use_cases/target/authentication/SignUp";
import {
  callUserTypeApi,
  CognitoAuthenticationResultStore,
  CognitoRefreshTokenStore,
  SleepCheckupUserStore,
} from "@/utils/auth";
import { getApiServerUrl, getAxios } from "@/utils/axios";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  email: EmailAddress;
  password: Password;
  registrationEntryId: string;
  surveyInfo: string;
};

interface LiteURLStateType {
  urlId: string;
  registrationEntryId: string;
  lastName: string;
  firstName: string;
  birthday: string;
  employeeNo?: string;
  insuranceSymbol?: string;
  insuranceNumber?: string;
  email: string;
  password: string;
  lastNameKana: string;
  firstNameKana: string;
}

function isLiteURLStateType(obj: any): obj is LiteURLStateType {
  return (
    typeof obj.urlId === "string" &&
    typeof obj.registrationEntryId === "string" &&
    typeof obj.lastName === "string" &&
    typeof obj.firstName === "string" &&
    typeof obj.birthday === "string" &&
    typeof obj.email === "string" &&
    typeof obj.password === "string" &&
    (typeof obj.employeeNo === "string" ||
      (typeof obj.insuranceSymbol === "string" &&
        typeof obj.insuranceNumber === "string"))
  );
}

const EMAIL_VALIDATION_ERROR_MESSAGES: ReadonlyMap<
  EmailVerificationCodeValidationError,
  string
> = new Map([["FormatError", "半角数字6桁で入力してください"]]);

export function CommonConfirm({
  email,
  onClickConfirm,
  onClickResend,
}: {
  email: string;
  onClickConfirm: (param: {
    cognitoCode: EmailVerificationCode;
    setLock: (lock: boolean) => void;
    setErrorMessage: (message: string) => void;
    setSnackbarText: (message: string) => void;
  }) => void;
  onClickResend: (param: {
    setErrorMessage: (message: string) => void;
    setSnackbarText: (message: string) => void;
  }) => void;
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarText, setSnackbarText] = useState("");
  const [cognitoCode, setCognitoCode] = useState<EmailVerificationCode>(
    new EmailVerificationCode("")
  );
  const [toShowResendDialog, setToShowResendDialog] = useState(false);
  const [isLocked, setLock] = useScreenLock();
  const codeValidationErrors = cognitoCode.validate();
  const hasErrors = codeValidationErrors.length !== 0;

  const handleClickConfirm = useCallback(() => {
    if (isLocked || hasErrors) {
      return;
    }
    onClickConfirm({ cognitoCode, setLock, setErrorMessage, setSnackbarText });
  }, [isLocked, hasErrors, setLock, onClickConfirm, cognitoCode]);

  const handleClickResend = () => {
    setToShowResendDialog(false);
    onClickResend({
      setErrorMessage,
      setSnackbarText,
    });
  };

  return (
    <>
      <ScrollToTop />
      <Stack spacing={12} sx={{ pt: 8, pb: 16, px: PX4 }}>
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            確認コードの入力
          </Typography>
          {/* エラーメッセージ */}
          <NonFieldError>{errorMessage}</NonFieldError>
          <Typography
            variant="subtitle2"
            textAlign="justify"
            sx={{ color: "text.secondary", wordBreak: "break-all" }}
          >
            入力したメールアドレス({email}
            )に登録用の確認コードをお送りしました。記載されている確認コードを入力してください。
          </Typography>
          <TextForm
            title="確認コード"
            placeholder="数字6桁"
            value={cognitoCode.value}
            onChange={(e) => {
              setCognitoCode(new EmailVerificationCode(e.target.value));
            }}
            validator={() =>
              codeValidationErrors.map(
                (e) => `※${EMAIL_VALIDATION_ERROR_MESSAGES.get(e)}`
              )
            }
          />
        </Stack>
        <Stack spacing={4} sx={{ mb: 6 }}>
          <Button
            variant="contained"
            size="large"
            sx={{ boxShadow: "none" }}
            onClick={handleClickConfirm}
            disabled={hasErrors}
          >
            確認する
          </Button>
          <Button
            variant="text"
            sx={{ p: 0 }}
            onClick={() => {
              setToShowResendDialog(true);
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", textDecoration: "underline" }}
            >
              コードを再送する
            </Typography>
          </Button>
        </Stack>
      </Stack>
      {/* コードを再送の確認ダイアログ */}
      <Dialog
        maxWidth="sm"
        open={toShowResendDialog}
        onClose={() => {
          setToShowResendDialog(false);
        }}
      >
        <DialogContent sx={{ m: 0, p: 6 }}>
          <Stack spacing={6}>
            <Stack spacing={2}>
              <Typography variant="body1">
                以下のメールアドレス宛に確認コードを再送します。
              </Typography>
              <Typography variant="body2" sx={{ color: "primary.main" }}>
                {email}
              </Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      setToShowResendDialog(false);
                    }}
                    sx={{ width: "100%" }}
                  >
                    キャンセル
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ width: "100%" }}
                    onClick={handleClickResend}
                  >
                    再送する
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
      {/* 確認コード再送処理の完了を通知するSnackbar */}
      <SCSnackbar
        open={!!snackbarText}
        severity="success"
        onClose={() => setSnackbarText("")}
      >
        {snackbarText}
      </SCSnackbar>
    </>
  );
}

function SurveyConfirmation({
  state,
  navigate,
}: {
  state: StateType;
  navigate: NavigateFunction;
}) {
  const { email, password, registrationEntryId, surveyInfo } = state;

  const handleClickConfirm = async ({
    cognitoCode,
    setLock,
    setErrorMessage,
  }: {
    cognitoCode: EmailVerificationCode;
    setLock: (lock: boolean) => void;
    setErrorMessage: (message: string) => void;
  }) => {
    setLock(true);

    try {
      // 対象者登録
      await registration(
        surveyInfo,
        email,
        password,
        registrationEntryId,
        cognitoCode
      );

      navigate(authenticationPath.getFullPath("RegistrationCompleted"), {
        state: {
          surveyInfo: surveyInfo,
        },
      });
    } catch (e: unknown) {
      setErrorMessage(getErrorMessage(e));
      // エラーアラートが画面内に表示されるようにスクロールをリセットする
      window.scrollTo(0, 0);
    } finally {
      setLock(false);
    }
  };

  const handleClickResend = async ({
    setErrorMessage,
    setSnackbarText,
  }: {
    setErrorMessage: (message: string) => void;
    setSnackbarText: (message: string) => void;
  }) => {
    try {
      await resendEmailVerificationCode(surveyInfo, registrationEntryId);
      setSnackbarText("確認コードを再送しました。");
    } catch (e: unknown) {
      setErrorMessage(getErrorMessage(e));
      // エラーアラートが画面内に表示されるようにスクロールをリセットする
      window.scrollTo(0, 0);
    }
  };

  if (!email || !password || !registrationEntryId || !surveyInfo) {
    // 遷移先がないエラー画面を表示する。
    return <GeneralError to="" />;
  }

  return (
    <CommonConfirm
      email={email.value}
      onClickConfirm={handleClickConfirm}
      onClickResend={handleClickResend}
    />
  );
}

function URLConfirmation({
  state,
  navigate,
}: {
  state: LiteURLStateType;
  navigate: NavigateFunction;
}) {
  const urlRegistrationEntryService = useURLRegistrationEntry();
  const {
    urlId,
    registrationEntryId,
    lastName,
    firstName,
    birthday,
    employeeNo,
    insuranceSymbol,
    insuranceNumber,
    email,
    password,
    lastNameKana,
    firstNameKana,
  } = state;

  const handleClickConfirm = async ({
    cognitoCode,
    setLock,
    setErrorMessage,
  }: {
    cognitoCode: EmailVerificationCode;
    setLock: (lock: boolean) => void;
    setErrorMessage: (message: string) => void;
  }) => {
    setLock(true);
    try {
      const param: URLRegistrationEntryPutParameter = {
        cognito_verification_code: cognitoCode.value,
        url_id: urlId,
        email: email,
        password: password,
        last_name: lastName,
        first_name: firstName,
        last_name_kana: lastNameKana,
        first_name_kana: firstNameKana,
        birthday: birthday,
      };
      if (employeeNo) {
        param.employee_id = employeeNo;
      }
      if (insuranceSymbol) {
        param.insurance_symbol = insuranceSymbol;
      }
      if (insuranceNumber) {
        param.insurance_number = insuranceNumber;
      }
      // 対象者登録
      const res = await urlRegistrationEntryService.put(
        registrationEntryId,
        param
      );
      const { survey_info, AuthenticationResult } = res.data;
      CognitoAuthenticationResultStore.setItem(AuthenticationResult);
      CognitoRefreshTokenStore.setItem({
        RefreshToken: AuthenticationResult.RefreshToken,
      });
      const userTypeResponse = await callUserTypeApi(
        getAxios(getApiServerUrl()),
        AuthenticationResult.IdToken
      );
      SleepCheckupUserStore.setItem(userTypeResponse.data);

      navigate(authenticationPath.getFullPath("RegistrationCompleted"), {
        state: {
          surveyInfo: survey_info,
        },
      });
    } catch (e: unknown) {
      setErrorMessage(getErrorMessage(e));
      // エラーアラートが画面内に表示されるようにスクロールをリセットする
      window.scrollTo(0, 0);
    } finally {
      setLock(false);
    }
  };

  const handleClickResend = async ({
    setErrorMessage,
    setSnackbarText,
  }: {
    setErrorMessage: (message: string) => void;
    setSnackbarText: (message: string) => void;
  }) => {
    try {
      await urlRegistrationEntryService.patch(registrationEntryId, {
        url_id: urlId,
      });
      setSnackbarText("確認コードを再送しました。");
    } catch (e: unknown) {
      setErrorMessage(getErrorMessage(e));
      // エラーアラートが画面内に表示されるようにスクロールをリセットする
      window.scrollTo(0, 0);
    }
  };

  if (
    [urlId, lastName, firstName, birthday, employeeNo, email, password].some(
      (v) => v == null
    )
  ) {
    // 遷移先がないエラー画面を表示する。
    return <GeneralError to="" />;
  }

  return (
    <CommonConfirm
      email={email}
      onClickConfirm={handleClickConfirm}
      onClickResend={handleClickResend}
    />
  );
}

/**
 * 確認ページ
 * メールアドレス検証コードの入力受付と検証を行う
 */
export function Confirmation() {
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state;

  if (isLiteURLStateType(state)) {
    return <URLConfirmation state={state} navigate={navigate} />;
  } else {
    return (
      <SurveyConfirmation
        state={(state as StateType) || {}}
        navigate={navigate}
      />
    );
  }
}
