export function validateLength(
  maxLength: number,
  name: string,
  value: string,
  callback?: (error: string[]) => void
) {
  const error =
    value.length <= maxLength
      ? []
      : [`${name}は${maxLength}文字以内で入力してください。`];
  callback?.(error);
  return error;
}

export function validateKatakana(
  name: string,
  value: string,
  callback?: (error: string[]) => void
) {
  const error =
    value.match(/^[\u30A1-\u30F6\u30fb\u30fc]+$/) != null
      ? []
      : [`${name}は全角カタカナで入力してください。`];
  callback?.(error);
  return error;
}

export function validateRequired(
  name: string,
  value: string,
  callback?: (error: string[]) => void
) {
  const error =
    value.length > 0 ? [] : [`${name}は必須です。入力してください。`];
  callback?.(error);
  return error;
}

export function validateLastName(
  name: string,
  value: string,
  callback?: (error: string[]) => void
) {
  const error = [
    ...validateRequired(name, value),
    ...validateLength(256, name, value),
  ];
  callback?.(error);
  return error;
}

export function validateFirstName(
  name: string,
  value: string,
  callback?: (error: string[]) => void
) {
  const error = [
    ...validateRequired(name, value),
    ...validateLength(256, name, value),
  ];
  callback?.(error);
  return error;
}

export function validateLastNameKana(
  name: string,
  value: string,
  callback?: (error: string[]) => void
) {
  const error = [
    ...validateRequired(name, value),
    ...validateLength(256, name, value),
    ...validateKatakana(name, value),
  ];
  callback?.(error);
  return error;
}

export function validateFirstNameKana(
  name: string,
  value: string,
  callback?: (error: string[]) => void
) {
  const error = [
    ...validateRequired(name, value),
    ...validateLength(256, name, value),
    ...validateKatakana(name, value),
  ];
  callback?.(error);
  return error;
}

export function validateEmployeeNo(
  name: string,
  value: string,
  callback?: (error: string[]) => void
) {
  const error = [
    ...validateRequired(name, value),
    ...validateLength(256, name, value),
  ];
  callback?.(error);
  return error;
}

export function validateInsuranceSymbol(
  name: string,
  value: string,
  callback?: (error: string[]) => void
) {
  const error = [
    ...validateRequired(name, value),
    ...validateLength(32, name, value),
  ];
  callback?.(error);
  return error;
}

export function validateInsuranceNumber(
  name: string,
  value: string,
  callback?: (error: string[]) => void
) {
  const error = [
    ...validateRequired(name, value),
    ...validateLength(32, name, value),
  ];
  callback?.(error);
  return error;
}
