import { NonFieldError } from "@/components/common/SCAlert";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import {
  EmailAddress,
  EmailAddressValidationError,
} from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import { StateType as SetNewPasswordState } from "@/sleep_compass_lite/pages/target/authentication/SetNewPassword";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { useResetUserPassword } from "@/sleep_compass_lite/use_cases/target/authentication/UseResetUserPassword";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  isSecondUser: boolean; // 2回目以降の受診者かどうか
  surveyInfo: string | null;
  urlId?: string;
};

export function ForgotPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const { verifyEmailAddress } = useResetUserPassword();

  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState<EmailAddress>(new EmailAddress(""));

  const { isSecondUser, surveyInfo, urlId } =
    (location.state as StateType) || {};

  const EMAIL_VALIDATION_ERROR_MESSAGES: Map<
    EmailAddressValidationError,
    string
  > = new Map([["FormatError", "メールアドレスの形式が正しくありません"]]);
  const emailValidationErrors = email.validate();

  const handleClickSubmit = async () => {
    if (emailValidationErrors.length !== 0) {
      // 何かしらのエラーがある場合は処理を行わない
      return;
    }

    try {
      await verifyEmailAddress(email);
      navigate(authenticationPath.getFullPath("SetNewPassword"), {
        state: {
          email,
          isSecondUser,
          surveyInfo,
          urlId,
        } as SetNewPasswordState,
      });
    } catch (e: unknown) {
      setErrorMessage(getErrorMessage(e));
      // エラーアラートが画面内に表示されるようにスクロールをリセットする
      window.scrollTo(0, 0);
    }
  };

  return (
    <Stack spacing={12} sx={{ pt: 8, pb: 22, px: PX4 }}>
      <Stack spacing={6}>
        <Typography variant="h6" align="center">
          パスワードを忘れた場合
        </Typography>
        {/* エラーメッセージ */}
        <NonFieldError>{errorMessage}</NonFieldError>
        <Typography variant="subtitle2">
          登録したメールアドレスにパスワード再設定用の確認コードをお送りします。メールアドレスを入力してください。
        </Typography>
        <TextForm
          title="メールアドレス"
          placeholder="例：sleep-compass@example.com"
          value={email.value}
          onChange={(e) => {
            setEmail(new EmailAddress(e.target.value));
          }}
          validator={() =>
            emailValidationErrors.map(
              (e) => `※${EMAIL_VALIDATION_ERROR_MESSAGES.get(e)}`
            )
          }
        />
      </Stack>
      <Button
        variant="contained"
        size="large"
        sx={{ boxShadow: "none" }}
        onClick={handleClickSubmit}
        disabled={emailValidationErrors.length !== 0}
      >
        送信する
      </Button>
    </Stack>
  );
}
