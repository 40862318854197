import {
  ErrorMessageTranslator,
  standardTranslator,
} from "@/components/auth/ErrorMessageTranslator";
import { PasswordCheckListItem } from "@/pages/examinee/activation/SignUp";
import {
  callConfirmForgotPasswordAPI,
  callResetPasswordAPI,
} from "@/utils/auth";
import { Box, Button, Typography } from "@mui/material";
import Axios, { AxiosInstance } from "axios";
import { FormEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorMessage from "../ErrorMessage";
import { PASSWORD_SYMBOL_REGEX } from "../activation/Constants";
import { BackdropIndicator } from "../common/BackdropIndicator";
import { SignInState } from "./Types";
import {
  CONFIRMATION_CODE_NUMBER_VALIDATORS,
  NEW_PASSWORD_CONFIRM_VALIDATORS,
  NEW_PASSWORD_WITHOUT_CHECKLIST_VALIDATORS,
  USERNAME_VALIDATORS,
  ValidatableTextField,
} from "./ValidatableTextField";

const toObject = (form: HTMLFormElement) =>
  Object.fromEntries(new FormData(form).entries());

type UsernameInputProps = {
  axios: AxiosInstance;
  onEnterUsername: (username: string) => void;
  setErrorMessage: (e: any) => void;
  setShowIndicator: (show: boolean) => void;
};
function UsernameInput(props: UsernameInputProps) {
  const [submittable, setSubmittable] = useState(false);
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    props.setShowIndicator(true);
    event.preventDefault();
    const form = toObject(event.currentTarget) as { username: string };
    try {
      await callResetPasswordAPI(props.axios, form.username);
      props.onEnterUsername(form.username);
      props.setErrorMessage(null);
    } catch (e: any) {
      if (Axios.isAxiosError(e)) {
        const res: any = e.response?.data;
        if (res?.username) {
          props.setErrorMessage(res.username[0]);
        } else {
          props.setErrorMessage(e.message);
        }
      } else {
        props.setErrorMessage(e.message);
      }
    } finally {
      props.setShowIndicator(false);
    }
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0px",
        gap: "40px",
        width: "432px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0px",
          gap: "16px",
        }}
      >
        <Typography variant="h3">パスワードを忘れた場合</Typography>
        <Typography variant="body2" color="text.primary">
          登録したメールアドレスにパスワード再設定用の確認コードをお送りします。ユーザー名を入力してください。
        </Typography>
      </Box>
      <ValidatableTextField
        margin="normal"
        fullWidth
        name="username"
        label="ユーザー名"
        id="username"
        autoComplete="username"
        validators={USERNAME_VALIDATORS}
        onChangeErrorMessage={(message) => setSubmittable(message === "")}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={!submittable}
      >
        送信する
      </Button>
    </Box>
  );
}

type ResetPasswordForm = {
  verificationCode: string;
  newPassword: string;
  reEnterNewPassword: string;
};
const verifyResetPasswordForm = (form: ResetPasswordForm) => {
  if (form.verificationCode.trim().length === 0) {
    throw new Error("確認コードは必須です。入力してください。");
  }
  if (form.newPassword !== form.reEnterNewPassword) {
    throw new Error(
      "The new password and the re-entered password do not match."
    );
  }
};
type ResetPasswordProps = {
  axios: AxiosInstance;
  username: string;
  setErrorMessage: (e: any) => void;
  onSuccess: () => void;
  setShowIndicator: (show: boolean) => void;
};
function getResetPasswordErrorMessage(error: any): string {
  //エラーをハンドリングしてメッセージを返却
  if (!Axios.isAxiosError(error)) {
    return error.message;
  }
  const res: any = error.response?.data;
  if (res == null) {
    return error.message;
  }
  if (Object.hasOwn(res, "cognito-error")) {
    if (
      res["cognito-error"].name === "CodeMismatchException" ||
      res["cognito-error"].name === "ExpiredCodeException"
    ) {
      return "Invalid verification code provided, please try again.";
    } else {
      return "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$";
    }
  } else if (res?.error_messages && res.error_messages.length > 0) {
    return res.error_messages[0];
  } else if (res?.confirmation_code) {
    return res.confirmation_code[0];
  } else if (res?.password) {
    return res.password[0];
  }
  return error.message;
}
function ResetPassword(props: ResetPasswordProps) {
  const [confirmationCodeSubmittable, setConfirmationCodeSubmittable] =
    useState(false);
  const [passwordSubmittable, setPasswordSubmittable] = useState(false);
  const [rePasswordSubmittable, setRePasswordSubmittable] = useState(false);
  const [password, setPassword] = useState("");

  const validators = {
    passwordLength: () => {
      if (password.length < 8 || 24 < password.length) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordAlphabetLowerAndUpper: () => {
      if (!(password.match(/[a-z]/) && password.match(/[A-Z]/))) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordDigit: () => {
      if (!password.match(/\d/)) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
    passwordSymbol: () => {
      if (!password.match(PASSWORD_SYMBOL_REGEX)) {
        return ["NG"]; // この返り値は使われない
      }
      return null;
    },
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    props.setShowIndicator(true);
    event.preventDefault();
    const form = toObject(event.currentTarget) as ResetPasswordForm;
    try {
      verifyResetPasswordForm(form);
      await callConfirmForgotPasswordAPI(
        props.axios,
        props.username,
        form.verificationCode,
        form.newPassword
      );
      props.onSuccess();
    } catch (e: any) {
      const message = getResetPasswordErrorMessage(e);
      props.setErrorMessage(message);
    } finally {
      props.setShowIndicator(false);
    }
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0px",
        gap: "40px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0px",
          gap: "16px",
        }}
      >
        <Typography variant="h3" color="text.primary">
          パスワードの再設定
        </Typography>
        <Typography variant="body2">
          登録したメールアドレスにパスワード再設定用の確認コードをお送りしました。確認コードと新パスワードを入力してください。
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0px",
          gap: "3px",
          width: "100%",
        }}
      >
        <ValidatableTextField
          margin="normal"
          fullWidth
          name="verificationCode"
          label="確認コード(6桁)"
          id="verificationCode"
          autoComplete="verificationCode"
          validators={CONFIRMATION_CODE_NUMBER_VALIDATORS}
          onChangeErrorMessage={(message) =>
            setConfirmationCodeSubmittable(message === "")
          }
        />
        <ValidatableTextField
          margin="normal"
          fullWidth
          name="newPassword"
          label="新しいパスワード"
          id="New Password"
          type="password"
          autoComplete="newPassword"
          validators={NEW_PASSWORD_WITHOUT_CHECKLIST_VALIDATORS}
          onChangeErrorMessage={(message) =>
            setPasswordSubmittable(
              message === "" &&
                !validators.passwordLength() &&
                !validators.passwordAlphabetLowerAndUpper() &&
                !validators.passwordDigit() &&
                !validators.passwordSymbol()
            )
          }
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 1 }}
        />
        <PasswordCheckListItem
          text="8文字以上24文字以下にしてください"
          validator={validators.passwordLength}
        />
        <PasswordCheckListItem
          text="大文字と小文字の半角英字を含めてください"
          validator={validators.passwordAlphabetLowerAndUpper}
        />
        <PasswordCheckListItem
          text="半角数字を含めてください"
          validator={validators.passwordDigit}
        />
        <PasswordCheckListItem
          text="記号を含めてください"
          validator={validators.passwordSymbol}
        />
        <ValidatableTextField
          margin="normal"
          fullWidth
          name="reEnterNewPassword"
          label="新しいパスワード(確認)"
          id="reEnterNewPassword"
          type="password"
          autoComplete="reEnterNewPassword"
          validators={NEW_PASSWORD_CONFIRM_VALIDATORS}
          onChangeErrorMessage={(message) =>
            setRePasswordSubmittable(message === "")
          }
        />
      </Box>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={
          !(
            confirmationCodeSubmittable &&
            passwordSubmittable &&
            rePasswordSubmittable
          )
        }
      >
        設定する
      </Button>
    </Box>
  );
}

type FacilityForgotPasswordProps = {
  axios: AxiosInstance;
  signInTo: string;
  translator?: ErrorMessageTranslator;
};
function FacilityForgotPassword(props: FacilityForgotPasswordProps) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [showIndicator, setShowIndicator] = useState(false);
  const [username, setUsername] = useState("");
  const [confirmCode, setConfirmCode] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const next = query.get("next") ?? props.signInTo;

  const onEnterUsername = (username: string) => {
    setUsername(username);
    setConfirmCode(true);
  };

  const translator = props.translator ?? standardTranslator;
  const onErrorMessageHandler = (message: string) => {
    const translated = translator(message);
    setErrorMessage(translated);
  };

  const onSuccessHandler = () => {
    const state =
      location.state ??
      new SignInState({
        message:
          "パスワードの再設定が完了しました。設定したパスワードでログインしてください。",
      });

    navigate(next, { state: state });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "80px 40px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0px",
          gap: "40px",
          width: "432px",
        }}
      >
        <BackdropIndicator open={showIndicator} />
        <ErrorMessage
          errorMessage={errorMessage ?? ""}
          sx={{ width: "100%" }}
        />
        {confirmCode === false ? (
          <UsernameInput
            axios={props.axios}
            onEnterUsername={onEnterUsername}
            setErrorMessage={onErrorMessageHandler}
            setShowIndicator={setShowIndicator}
          />
        ) : (
          <ResetPassword
            axios={props.axios}
            username={username}
            setErrorMessage={onErrorMessageHandler}
            setShowIndicator={setShowIndicator}
            onSuccess={onSuccessHandler}
          />
        )}
      </Box>
    </Box>
  );
}
export default FacilityForgotPassword;
