import { NonFieldError } from "@/components/common/SCAlert";
import { ScrollToTop } from "@/components/common/ScrollToTop";
import { useScreenLock } from "@/components/ScreenLock";
import { GeneralError } from "@/components/sleep_checkup_v1/GeneralError";
import { TextForm } from "@/components/sleep_checkup_v1/TextForm";
import Expired from "@/sleep_compass_lite/components/target/Expired";
import {
  URLInformation,
  useURLInformation,
} from "@/sleep_compass_lite/components/target/greetings/hooks";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import {
  validateEmployeeNo,
  validateFirstName,
  validateFirstNameKana,
  validateInsuranceNumber,
  validateInsuranceSymbol,
  validateLastName,
  validateLastNameKana,
} from "@/sleep_compass_lite/domain_models/target/authentication/validators";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import { getErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import {
  GetUserInfoResult,
  useUserInfo,
} from "@/sleep_compass_lite/use_cases/target/authentication/UserInfo";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function toTop<R>(callback: () => R) {
  const result = callback();
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  return result;
}

interface URLAccountRegisterState {
  urlId?: string;
  surveyInfo?: string;
}

/**
 * URL形式のサインイン
 */
export function URLAccountRegister() {
  const location = useLocation();
  const navigate = useNavigate();

  const [urlInformation, setURLInformation] = useState<URLInformation>();
  const urlInformationService = useURLInformation();
  const [userInfo, setUserInfo] = useState<GetUserInfoResult>();
  const userInfoService = useUserInfo();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLocked, setLock] = useScreenLock();

  const { urlId, surveyInfo } = (location.state ??
    {}) as URLAccountRegisterState;

  const [isConfirm, setConfirm] = useState(false);

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState<string[]>();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState<string[]>();

  const [lastNameKana, setLastNameKana] = useState("");
  const [lastNameKanaError, setLastNameKanaError] = useState<string[]>();

  const [firstNameKana, setFirstNameKana] = useState("");
  const [firstNameKanaError, setFirstNameKanaError] = useState<string[]>();

  const [employeeNo, setEmployeeNo] = useState("");
  const [employeeNoError, setEmployeeNoError] = useState<string[] | null>();

  const [insuranceSymbol, setInsuranceSymbol] = useState("");
  const [insuranceSymbolError, setInsuranceSymbolError] = useState<
    string[] | null
  >();

  const [insuranceNumber, setInsuranceNumber] = useState("");
  const [insuranceNumberError, setInsuranceNumberError] = useState<
    string[] | null
  >();

  const isDisabled = useCallback(() => {
    return [
      lastNameError,
      firstNameError,
      lastNameKanaError,
      firstNameKanaError,
      urlInformation?.id_type === "EMPLOYEE_ID" ? employeeNoError : [],
      urlInformation?.id_type === "INSURANCE_CARD" ? insuranceSymbolError : [],
      urlInformation?.id_type === "INSURANCE_CARD" ? insuranceNumberError : [],
    ].some((v) => v == null || v.length > 0);
  }, [
    urlInformation,
    lastNameError,
    firstNameError,
    lastNameKanaError,
    firstNameKanaError,
    employeeNoError,
    insuranceSymbolError,
    insuranceNumberError,
  ]);

  useEffect(() => {
    (async () => {
      if (urlId == null) return;
      const response = await urlInformationService.get(urlId);
      setURLInformation(response.data);
    })();
  }, [urlId, urlInformationService]);

  useEffect(() => {
    (async () => {
      if (surveyInfo == null) return;
      const res = await userInfoService.get(surveyInfo);
      const userInfo = res.data;
      setUserInfo(userInfo);

      setFirstName(userInfo.first_name ?? "");
      setFirstNameError([]);

      setLastName(userInfo.last_name ?? "");
      setLastNameError([]);

      setFirstNameKana(userInfo.first_name_kana ?? "");
      setFirstNameKanaError([]);

      setLastNameKana(userInfo.last_name_kana ?? "");
      setLastNameKanaError([]);

      if (urlInformation?.id_type === "EMPLOYEE_ID") {
        setEmployeeNo(userInfo.employee_id ?? "");
        setEmployeeNoError(userInfo.employee_id == null ? null : []);
      } else if (urlInformation?.id_type === "INSURANCE_CARD") {
        setInsuranceSymbol(userInfo.insurance_symbol ?? "");
        setInsuranceSymbolError(userInfo.insurance_symbol == null ? null : []);
        setInsuranceNumber(userInfo.insurance_number ?? "");
        setInsuranceNumberError(userInfo.insurance_number == null ? null : []);
      }
    })();
  }, [surveyInfo, userInfoService, urlInformation]);

  const handleClickRegister = async () => {
    if (isLocked || surveyInfo == null) return;
    setLock(true);
    try {
      await userInfoService.post(surveyInfo, {
        last_name: lastName,
        last_name_kana: lastNameKana,
        first_name: firstName,
        first_name_kana: firstNameKana,
        employee_id:
          urlInformation?.id_type === "EMPLOYEE_ID" ? employeeNo : null,
        insurance_symbol:
          urlInformation?.id_type === "INSURANCE_CARD" ? insuranceSymbol : null,
        insurance_number:
          urlInformation?.id_type === "INSURANCE_CARD" ? insuranceNumber : null,
      });
      navigate(authenticationPath.getFullPath("RegistrationCompleted"), {
        state: {
          surveyInfo,
          redirectTo: authenticationPath.getFullPath("URLSignIn"),
        },
      });
    } catch (e) {
      const errorMessage = getErrorMessage(e, "");
      if (errorMessage) {
        setErrorMessage(errorMessage);
      }
      setLock(false);
      window.scrollTo(0, 0);
    }
  };
  if (urlId == null) {
    return <GeneralError to="" />;
  }
  if (userInfo?.already_progressed) {
    return (
      <Expired
        title="既に回答済みです"
        message={`この調査には既に回答済みです。\n問診の回答を行いたい場合やレポートを確認したい場合は、\nメールのURLよりログインしてください。`}
      />
    );
  }
  return (
    <>
      <ScrollToTop />
      <Stack spacing={12} sx={{ pt: 8, pb: 16, px: PX4 }}>
        <Stack spacing={6}>
          <Typography variant="h6" align="center">
            {!isConfirm ? "アカウント情報を登録" : "登録内容を確認"}
          </Typography>
          <NonFieldError>{errorMessage}</NonFieldError>
          <Typography
            variant="subtitle2"
            textAlign="justify"
            sx={{ color: "text.secondary" }}
          >
            {!isConfirm
              ? "今回の測定に用いるあなたの情報を登録します。内容に変更がある場合は修正してください。"
              : "今回の測定に用いるあなたの情報です。内容に誤りがないか、ご確認ください。"}
          </Typography>
          <Stack spacing={1}>
            <Typography variant="subtitle2">
              {isConfirm ? "氏名" : "お名前"}
            </Typography>
            {!isConfirm ? (
              <Stack direction="row" spacing={2}>
                <TextForm
                  placeholder="姓"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  validator={() =>
                    validateLastName("姓", lastName, setLastNameError)
                  }
                ></TextForm>
                <TextForm
                  placeholder="名"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  validator={() =>
                    validateFirstName("名", firstName, setFirstNameError)
                  }
                ></TextForm>
              </Stack>
            ) : (
              <Stack spacing={6}>
                <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                  {lastName} {firstName}
                </Typography>
                <Divider />
              </Stack>
            )}
          </Stack>
          <Stack spacing={1}>
            <Typography variant="subtitle2">
              {isConfirm ? "フリガナ" : "お名前（フリガナ）"}
            </Typography>
            {!isConfirm ? (
              <Stack direction="row" spacing={2}>
                <TextForm
                  placeholder="セイ"
                  value={lastNameKana}
                  onChange={(e) => setLastNameKana(e.target.value)}
                  validator={() =>
                    validateLastNameKana(
                      "セイ",
                      lastNameKana,
                      setLastNameKanaError
                    )
                  }
                ></TextForm>
                <TextForm
                  placeholder="メイ"
                  value={firstNameKana}
                  onChange={(e) => setFirstNameKana(e.target.value)}
                  validator={() =>
                    validateFirstNameKana(
                      "メイ",
                      firstNameKana,
                      setFirstNameKanaError
                    )
                  }
                ></TextForm>
              </Stack>
            ) : (
              <Stack spacing={6}>
                <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                  {lastNameKana} {firstNameKana}
                </Typography>
                <Divider />
              </Stack>
            )}
          </Stack>
          {isConfirm && (
            <Stack spacing={2}>
              <Typography variant="subtitle2">生年月日</Typography>
              <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                {userInfo?.birthday?.replace(
                  /^(\d{4})-(\d{2})-(\d{2})$/,
                  "$1年$2月$3日"
                )}
              </Typography>
              <Typography variant="caption">
                ※生年月日は修正できません。
              </Typography>
              <Divider />
            </Stack>
          )}
          {urlInformation?.id_type === "EMPLOYEE_ID" && (
            <Stack spacing={2}>
              <Typography variant="subtitle2">社員番号</Typography>
              {!isConfirm ? (
                <TextForm
                  placeholder="社員番号"
                  value={employeeNo}
                  onChange={(e) => setEmployeeNo(e.target.value)}
                  validator={() =>
                    validateEmployeeNo(
                      "社員番号",
                      employeeNo,
                      setEmployeeNoError
                    )
                  }
                ></TextForm>
              ) : (
                <Stack spacing={6}>
                  <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                    {employeeNo}
                  </Typography>
                  <Divider />
                </Stack>
              )}
            </Stack>
          )}
          {urlInformation?.id_type === "INSURANCE_CARD" && (
            <Stack spacing={1}>
              <Typography variant="subtitle2">保険証の記号・番号</Typography>
              {isConfirm ? (
                <Stack spacing={6}>
                  <Stack spacing={1}>
                    <Typography variant="body1">
                      <Typography component="span" color="textSecondary">
                        記号:
                      </Typography>
                      <Typography
                        component="span"
                        sx={{ marginLeft: 2, wordBreak: "break-all" }}
                      >
                        {insuranceSymbol}
                      </Typography>
                    </Typography>
                    <Typography variant="body1">
                      <Typography component="span" color="textSecondary">
                        番号:
                      </Typography>
                      <Typography
                        component="span"
                        sx={{ marginLeft: 2, wordBreak: "break-all" }}
                      >
                        {insuranceNumber}
                      </Typography>
                    </Typography>
                  </Stack>
                  <Divider />
                </Stack>
              ) : (
                <Stack spacing={4}>
                  <Stack direction="row" spacing={2}>
                    <TextForm
                      placeholder="記号"
                      value={insuranceSymbol}
                      onChange={(e) => setInsuranceSymbol(e.target.value)}
                      validator={() =>
                        validateInsuranceSymbol(
                          "記号",
                          insuranceSymbol,
                          setInsuranceSymbolError
                        )
                      }
                    ></TextForm>
                    <TextForm
                      placeholder="番号"
                      value={insuranceNumber}
                      onChange={(e) => setInsuranceNumber(e.target.value)}
                      validator={() =>
                        validateInsuranceNumber(
                          "番号",
                          insuranceNumber,
                          setInsuranceNumberError
                        )
                      }
                    ></TextForm>
                  </Stack>
                  <Stack direction="row" sx={{ backgroundColor: "#F6F5F4" }}>
                    <Stack
                      component="span"
                      sx={{
                        marginX: "auto",
                        marginY: 3,
                      }}
                    >
                      <img
                        src="/img/image_lite_insurance_card.png"
                        alt="Insurance Card"
                        loading="lazy"
                      />
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>
          )}
          {isConfirm && (
            <Stack spacing={2}>
              <Typography variant="subtitle2">メールアドレス</Typography>
              <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                {userInfo?.email}
              </Typography>
              <Typography variant="caption">
                ※メールアドレスは修正できません。
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack spacing={4}>
          {!isConfirm ? (
            <Button
              variant="contained"
              size="large"
              sx={{
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
              disabled={isDisabled()}
              onClick={() => toTop(() => setConfirm(true))}
            >
              登録内容の確認に進む
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                size="large"
                sx={{
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
                onClick={handleClickRegister}
              >
                登録する
              </Button>
              <Button
                variant="text"
                size="large"
                sx={{
                  boxShadow: "none",
                  color: "text.secondary",
                }}
                onClick={() =>
                  toTop(() => {
                    setConfirm(false);
                  })
                }
              >
                <Typography
                  variant="body2"
                  sx={{ textDecoration: "underline #c6c6c6" }}
                >
                  内容を修正する
                </Typography>
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
}
