import { ScrollToTop } from "@/components/common/ScrollToTop";
import { RegistrationCompletedImage } from "@/sleep_compass_lite/components/target/RegistrationCompletedImage";
import { PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { TargetAuthenticated as Authenticated } from "@/sleep_compass_lite/components/target/TargetAuthenticated";
import { interviewPath } from "@/sleep_compass_lite/domain_models/target/InterviewPath";
import { Button, Stack, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

// このページが受け取るstateの型
export type StateType = {
  surveyInfo: string;
  redirectTo?: string;
};

/**
 * 登録完了ページ
 */
export function RegistrationCompleted() {
  const location = useLocation();

  const { surveyInfo } = (location.state as StateType) || {};

  return (
    <Authenticated>
      <ScrollToTop />
      <Stack spacing={12} sx={{ pt: 8, pb: 16, px: PX4 }}>
        <Stack spacing={6} alignItems="center">
          <RegistrationCompletedImage sx={{ px: "41.5px" }} />
          <Typography variant="h6">登録完了</Typography>
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ color: "text.secondary" }}
          >
            以上でアカウント登録は完了です
            <br />
            続いて「事前問診」にご回答ください
          </Typography>
        </Stack>
        <Button
          component={Link}
          to={interviewPath.getFullPath("PrimaryInterviewIntroduction", {
            ":surveyInfoUid": surveyInfo,
          })}
          variant="contained"
          size="large"
          sx={{ boxShadow: "none" }}
        >
          事前問診に回答する
        </Button>
      </Stack>
    </Authenticated>
  );
}
