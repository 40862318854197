import { useForkRef, useTheme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { getTransitionProps, reflow } from "@mui/material/transitions/utils";
import {
  cloneElement,
  CSSProperties,
  forwardRef,
  ReactElement,
  useRef,
} from "react";
import { Transition, TransitionStatus } from "react-transition-group";

/**
 * 本ファイルは下記を可能な限り流用した上で、一部カスタマイズしています。
 * cf. https://github.com/mui/material-ui/blob/v5.14.14/packages/mui-material/src/Fade/Fade.js
 */

const styles: Partial<Record<TransitionStatus, CSSProperties>> = {
  entering: {
    opacity: 1,
    transform: "none",
  },
  entered: {
    opacity: 1,
    transform: "none",
  },
  exiting: {
    opacity: 0,
    transform: "none",
  },
};

export type CustomTransitionProps = Omit<TransitionProps, "children"> & {
  children: ReactElement<any, any>;
};

const GetTransition = (transform: string) => {
  return forwardRef<HTMLElement, CustomTransitionProps>((props, ref) => {
    const theme = useTheme();
    const defaultTimeout = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen,
    };

    const {
      addEndListener,
      appear = true,
      children,
      easing,
      in: inProp,
      onEnter,
      onEntered,
      onEntering,
      onExit,
      onExited,
      onExiting,
      style,
      timeout = defaultTimeout,
      ...other
    } = props;

    const enableStrictModeCompat = true;
    const nodeRef = useRef(null);
    const handleRef = useForkRef(
      nodeRef,
      // @ts-ignore
      children.ref,
      ref
    );

    const normalizedTransitionCallback =
      (callback?: (node: HTMLElement, isAppearing: boolean) => void) =>
      (maybeIsAppearing?: boolean) => {
        if (callback) {
          const node = nodeRef.current;
          if (node == null || callback == null) {
            return;
          }

          // onEnterXxx and onExitXxx callbacks have a different arguments.length value.
          if (maybeIsAppearing === undefined) {
            callback(node, false);
          } else {
            callback(node, maybeIsAppearing);
          }
        }
      };

    const handleEntering = normalizedTransitionCallback(onEntering);

    const handleEnter = normalizedTransitionCallback((node, isAppearing) => {
      reflow(node); // So the animation always start from the start.

      const transitionProps = getTransitionProps(
        { style, timeout, easing },
        {
          mode: "enter",
        }
      );

      node.style.transition = theme.transitions.create(
        ["opacity", "transform"],
        transitionProps
      );

      if (onEnter) {
        onEnter(node, isAppearing);
      }
    });

    const handleEntered = normalizedTransitionCallback(onEntered);

    const handleExiting = normalizedTransitionCallback(onExiting);

    const handleExit = normalizedTransitionCallback((node) => {
      const transitionProps = getTransitionProps(
        { style, timeout, easing },
        {
          mode: "exit",
        }
      );

      node.style.transition = theme.transitions.create(
        ["opacity", "transform"],
        transitionProps
      );

      if (onExit) {
        onExit(node);
      }
    });

    const handleExited = normalizedTransitionCallback(onExited);

    const handleAddEndListener = (next: () => void) => {
      if (addEndListener && nodeRef.current != null) {
        // Old call signature before `react-transition-group` implemented `nodeRef`
        addEndListener(nodeRef.current, next);
      }
    };

    return (
      <Transition
        appear={appear}
        in={inProp}
        nodeRef={enableStrictModeCompat ? nodeRef : undefined}
        onEnter={handleEnter}
        onEntered={handleEntered}
        onEntering={handleEntering}
        onExit={handleExit}
        onExited={handleExited}
        onExiting={handleExiting}
        addEndListener={handleAddEndListener}
        timeout={timeout}
        {...other}
      >
        {(state, childProps) => {
          return cloneElement(children, {
            style: {
              opacity: 0,
              transform,
              visibility: state === "exited" && !inProp ? "hidden" : undefined,
              ...styles[state],
              ...style,
              ...children.props.style,
            },
            ref: handleRef,
            ...childProps,
          });
        }}
      </Transition>
    );
  });
};

export const SlideFromRightAndFade = GetTransition("translateX(500px)");
export const SlideFromLeftAndFade = GetTransition("translateX(-500px)");
