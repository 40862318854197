import { NonFieldError } from "@/components/common/SCAlert";
import { Copyright } from "@/components/examinee/Copyright";
import {
  createInterviewAnswer,
  getNewInterviewAnswer,
} from "@/components/examinee/interview/AnswerService";
import { InterviewAppBar } from "@/components/examinee/interview/InterviewAppBar";
import { PageSummary } from "@/components/examinee/interview/PageSummary";
import { ProgressBar } from "@/components/examinee/interview/ProgressBar";
import { SingleQuestionGroupPage } from "@/components/examinee/interview/SingleQuestionGroupPage";
import {
  AnswerChangeHandler,
  InterviewAnswer,
  InterviewEntry,
  Screen as QuestionScreen,
} from "@/components/examinee/interview/Types";
import { ExamineePageProps } from "@/pages/examinee/ExamineePageProps";
import { MX4, PX4 } from "@/sleep_compass_lite/components/target/StackStyles";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { getErrorMessage as getCommonErrorMessage } from "@/sleep_compass_lite/presentation_lib/GetErrorMessage";
import { useInterviewScreen } from "@/sleep_compass_lite/use_cases/target/interview/UseInterviewScreen";
import { Box, Container, Stack } from "@mui/material";
import Axios from "axios";
import { useEffect, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";

export type PostAnswerHandler = (
  interviewAnswer: InterviewAnswer
) => Promise<InterviewEntry>;

export type GetNextScreenHandler = (entry: InterviewEntry) => Promise<string>;

export type GetNextPageHandler = () => Promise<string>;

const DEFAULT_ERROR_MESSAGE =
  "エラーが発生しました。\nメールに記載されたURLから、問診ページに再アクセスしてください。";

export const FOOTER_HEIGHT = "85px";
export const BACKGROUND_COLOR = "background.default";

function getErrorMessage(err: unknown) {
  const error = Axios.isAxiosError(err) ? new ApiError(err.response) : err;
  return getCommonErrorMessage(error, DEFAULT_ERROR_MESSAGE);
}

type InterviewPageState = {
  /** 前画面の {@link QuestionScreen} */
  prevScreen: QuestionScreen | null;
  /** 戻り先のパス */
  prevPathname?: string;
};

type PageSummaryCreator = (questionScreen: QuestionScreen) => PageSummary;

type InterviewPageProps = {
  pageProps: ExamineePageProps;
  context: InterviewContext;
};
type InterviewContext = {
  /** サーベイ情報のUUID */
  surveyInfoUid: string;
  /** 問診のUUID */
  interviewId: string;
  /** 質問画面のID */
  screenId: string;
  /** 問診ページのPageSummaryを生成するクロージャー */
  pageSummaryCreator: PageSummaryCreator;
  /** 回答送信用クロージャー */
  postAnswer: PostAnswerHandler;
  /** 次の質問への遷移用クロージャー */
  getNextScreen: GetNextScreenHandler;
  /** 次の画面への遷移用クロージャー */
  getNextPage: GetNextPageHandler;
};
/**
 * 問診ページのベースコンポーネント
 * プロパティの指定で動作を変更することで、事前問診と毎日問診で流用する
 */
export function Interview({ pageProps, context }: InterviewPageProps) {
  const [pageSummary, setPageSummary] = useState<PageSummary | null>(null);
  const [answer, setAnswer] = useState<InterviewAnswer | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const { getInterviewScreen } = useInterviewScreen();

  const state =
    location.state != null ? (location.state as InterviewPageState) : undefined;

  useEffect(() => {
    async function initState() {
      try {
        const questionScreen = await getInterviewScreen(context.screenId);

        setPageSummary(context.pageSummaryCreator(questionScreen));
        setAnswer(
          createInterviewAnswer(parseInt(context.interviewId), questionScreen)
        );
      } catch (e) {
        setErrorMessage(getErrorMessage(e));
      }
    }
    initState();
  }, [context, getInterviewScreen]);

  const postAnswer = async (answer: InterviewAnswer) => {
    try {
      const interviewEntry = await context.postAnswer(answer);

      const getNextPath = async (interviewEntry: InterviewEntry) => {
        // Note: 問診の終了判定
        if (interviewEntry.screen.id != null) {
          // 終了していなかったら、次の問題へ
          return await context.getNextScreen(interviewEntry);
        } else {
          // 終了していたら、次の画面へ
          return await context.getNextPage();
        }
      };

      const path = await getNextPath(interviewEntry);
      navigate(path, {
        state: { prevScreen: pageSummary?.screen },
      });
    } catch (e: unknown) {
      setErrorMessage(getErrorMessage(e));
    }
  };

  const handleBack = () => {
    if (state?.prevPathname != null) {
      // pathname が指定されている場合、指定された pathname へ移動
      navigate(state.prevPathname);
    } else {
      // 指定がない場合は history back
      navigate(-1);
    }
  };

  // 質問への回答が変更されたとき
  const onChange: AnswerChangeHandler = (questionAnswer) => {
    if (answer == null) {
      throw new Error("answer is null");
    }

    setAnswer(getNewInterviewAnswer(answer, questionAnswer));
  };

  const onNext = () => {
    if (answer == null) {
      throw new Error("answer is null");
    }

    postAnswer(answer);
  };

  const onSelect: AnswerChangeHandler = (questionAnswer) => {
    if (answer == null) {
      throw new Error("answer is null");
    }

    postAnswer(getNewInterviewAnswer(answer, questionAnswer));
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        position: "relative",
        backgroundColor: "background.blankSpace",
      }}
    >
      <InterviewAppBar
        height={pageProps.headerHeight}
        title={pageSummary?.pageTitle ?? ""}
        onClickBack={canBack(location) ? handleBack : undefined}
      />
      <Container
        maxWidth="sm"
        sx={{
          p: 0,
          pt: 0,
          pl: { xs: 0, sm: 0 },
          pr: { xs: 0, sm: 0 },
          pb: FOOTER_HEIGHT,
          backgroundColor: BACKGROUND_COLOR,
          minHeight: `calc(100vh - ${pageProps.headerHeight}px - ${FOOTER_HEIGHT})`,
        }}
      >
        {errorMessage && (
          <Stack sx={{ pt: 8, px: PX4, backgroundColor: "white" }}>
            <NonFieldError>{errorMessage}</NonFieldError>
          </Stack>
        )}
        {answer != null &&
          pageSummary != null &&
          pageSummary.pageType === "Single" && (
            <SingleQuestionGroupPage
              progress={<ProgressBar value={pageSummary.progress} />}
              questionGroup={pageSummary.questionGroup}
              questionGroupAnswer={answer.screen.question_group}
              prevQuestionGroup={state?.prevScreen?.question_group}
              backgroundColor={BACKGROUND_COLOR}
              stackSx={{ mx: MX4, my: 6 }}
              questionStackSx={{ mx: MX4, my: 6 }}
              onChange={onChange}
              onNext={onNext}
              onSelect={onSelect}
            />
          )}
      </Container>
      {/* フッターの幅をクライアントエリアの幅、かつ、配置を画面の左右中央にするため、Stackを2層にする */}
      <Stack alignItems="center">
        <Stack
          alignItems="center"
          justifyContent="center"
          maxWidth="sm"
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: FOOTER_HEIGHT,
            backgroundColor: BACKGROUND_COLOR,
          }}
        >
          <Copyright />
        </Stack>
      </Stack>
    </Box>
  );
}

/**
 * 直前の質問に戻ることが可能か否かを判定
 * ヘッダの戻るボタンの表示制御に使われる
 * @param location useLocationで取得したlocation
 * @returns 戻ることが可能か否かを示す真偽値
 */
export function canBack(location: Location): boolean {
  // このページ間で画面遷移した場合には、location.state.prevScreenに直前のScreenEntryが格納されている。
  // 理想は、BrowserのHistory Stackから一つ前のURLを取得し、バックの可否を判定したいが、URLにアクセスできないので、location.stateを使う
  if (location.state == null) {
    return false;
  }
  const state = location.state as InterviewPageState;
  if (state.prevScreen == null && state.prevPathname == null) {
    return false;
  }

  return true;
}
