import { AxiosInstance, AxiosResponse } from "axios";
const SLEEP_CHECKUP_USER_KEY = `com.accelstars.${
  process.env.REACT_APP_ENV ?? ""
}.sleep_checkup.sleep.checkup.user`;
const COGNITO_AUTHENTICATION_RESULT_KEY = `com.accelstars.${
  process.env.REACT_APP_ENV ?? ""
}.cognito.authentication_result.key`;
const COGNITO_CHALLENGE_RESULT_KEY = `com.accelstars.${
  process.env.REACT_APP_ENV ?? ""
}.cognito.challenge_result.key`;
const COGNITO_SESSION_KEY = `com.accelstars.${
  process.env.REACT_APP_ENV ?? ""
}.cognito.session.key`;
const COGNITO_REFRESH_TOKEN_KEY = `com.accelstars.${
  process.env.REACT_APP_ENV ?? ""
}.cognito.refresh_token.key`;

export type Group =
  | "facility_report_confirmor"
  | "facility_member"
  | "facility_admin"
  | "accelstars";

interface SleepCheckupUser {
  username: string;
  user_type: number;
  group_names: Group[];
}

interface Store<T> {
  getItem(): T | null;
  setItem(param: T): void;
  removeItem(): void;
}

export interface CognitoAuthenticationResult {
  AccessToken: string;
  ExpiresIn: number;
  TokenType: string;
  RefreshToken: string;
  IdToken: string;
}

interface CognitoRefreshToken {
  RefreshToken: string;
}

export interface CognitoChallengeParameters {
  USER_ID_FOR_SRP: string;
  requiredAttributes: string;
  userAttributes: string;
}

interface CognitoSignUpResult {
  UserConfirmed: boolean;
  CodeDeliveryDetails: any;
  UserSub: string;
}

class LocalStorageStore<T> implements Store<T> {
  readonly key: string;
  constructor(key: string) {
    this.key = key;
  }

  getItem(): T | null {
    const json = localStorage.getItem(this.key);
    if (json == null) {
      return null;
    }
    return JSON.parse(json);
  }

  setItem(param: T): void {
    localStorage.setItem(this.key, JSON.stringify(param));
  }

  removeItem(): void {
    localStorage.removeItem(this.key);
  }
}

export const SleepCheckupUserStore = new LocalStorageStore<SleepCheckupUser>(
  SLEEP_CHECKUP_USER_KEY
);

export const CognitoAuthenticationResultStore =
  new LocalStorageStore<CognitoAuthenticationResult>(
    COGNITO_AUTHENTICATION_RESULT_KEY
  );

export const CognitoRefreshTokenStore =
  new LocalStorageStore<CognitoRefreshToken>(COGNITO_REFRESH_TOKEN_KEY);

export const CognitoChallengeParametersStore =
  new LocalStorageStore<CognitoChallengeParameters>(
    COGNITO_CHALLENGE_RESULT_KEY
  );

export const CognitoSession = new LocalStorageStore<string>(
  COGNITO_SESSION_KEY
);

export type SIGNIN_TYPE =
  | "init"
  | "success"
  | "mfaRequired"
  | "newPasswordRequired";

export function callInitiateAuthAPI(
  axios: AxiosInstance,
  username: string,
  password: string
): Promise<SIGNIN_TYPE> {
  // cognito 認証フローAPI
  return axios
    .post("/api/initiate_auth/", {
      username: username,
      password: password,
    })
    .then((res: AxiosResponse) => {
      if (res.data?.ChallengeName == null) {
        // ChallengeNameが無い場合、認証が成功している
        CognitoAuthenticationResultStore.setItem(res.data.AuthenticationResult);
        return callUserTypeApi(
          axios,
          res.data.AuthenticationResult.IdToken
        ).then((res) => {
          SleepCheckupUserStore.setItem(res.data);
          return "success";
        });
      } else if (res.data?.ChallengeName === "NEW_PASSWORD_REQUIRED") {
        // NEW_PASSWORD_REQUIREDがある場合、初期パスワード再設定が必要
        CognitoChallengeParametersStore.setItem(res.data.ChallengeParameters);
        CognitoSession.setItem(res.data.Session);
        return "newPasswordRequired";
      } else {
        throw new Error(
          `unknown data is returned from callInitiateAuthAPI. ${res.data}`
        );
      }
    });
}

export function callAuthChallengeAPI(
  axios: AxiosInstance,
  username: string,
  new_password: string,
  session: string
): Promise<SIGNIN_TYPE> {
  // 初期パスワード変更API
  return axios
    .post("/api/auth_challenge/", {
      username: username,
      new_password: new_password,
      session: session,
    })
    .then((res: AxiosResponse) => {
      CognitoAuthenticationResultStore.setItem(res.data.AuthenticationResult);
      CognitoRefreshTokenStore.setItem({
        RefreshToken: res.data.AuthenticationResult.RefreshToken,
      });
      return callUserTypeApi(axios, res.data.AuthenticationResult.IdToken);
    })
    .then((res) => {
      SleepCheckupUserStore.setItem(res.data);
      return "success";
    });
}

export function callUserTypeApi(
  axios: AxiosInstance,
  idToken: string
): Promise<AxiosResponse> {
  return axios.get("/api/user_type/", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  });
}

export function callCustomInitiateAuthAPI(
  axios: AxiosInstance,
  username: string,
  password: string
): Promise<SIGNIN_TYPE> {
  // カスタム認証 API
  return axios
    .post("/api/custom_initiate_auth/", {
      username: username,
      password: password,
    })
    .then((res: AxiosResponse) => {
      if (res.data?.ChallengeName === "CUSTOM_CHALLENGE") {
        CognitoSession.setItem(res.data.Session);
        return "mfaRequired";
      } else if (res.data?.ChallengeName === "NEW_PASSWORD_REQUIRED") {
        // NEW_PASSWORD_REQUIREDがある場合、初期パスワード再設定が必要
        CognitoChallengeParametersStore.setItem(res.data.ChallengeParameters);
        CognitoSession.setItem(res.data.Session);
        return "newPasswordRequired";
      } else {
        throw new Error(
          `unknown data is returned from custom_initiate_auth. ${res.data}`
        );
      }
    });
}

export function callCustomAuthChallengeAPI(
  axios: AxiosInstance,
  username: string,
  confirmation_code: string
): Promise<SIGNIN_TYPE> {
  const custom_auth_api_url = "/api/custom_auth_challenge/";
  const params = {
    username: username,
    confirmation_code: confirmation_code,
    session: CognitoSession.getItem(),
  };
  return axios.post(custom_auth_api_url, params).then((res: AxiosResponse) => {
    if (res.data.Session) CognitoSession.setItem(res.data.Session);
    if (res.data?.ChallengeName === "CUSTOM_CHALLENGE") {
      return "mfaRequired";
    }
    CognitoAuthenticationResultStore.setItem(res.data.AuthenticationResult);
    CognitoRefreshTokenStore.setItem({
      RefreshToken: res.data.AuthenticationResult.RefreshToken,
    });
    return callUserTypeApi(axios, res.data.AuthenticationResult.IdToken).then(
      (res) => {
        SleepCheckupUserStore.setItem(res.data);
        return "success";
      }
    );
  });
}

export function callResetPasswordAPI(axios: AxiosInstance, username: string) {
  // パスワードリセット API
  return axios.post("/api/reset_user_password/", {
    username: username,
  });
}

export function callRefreshTokenAPI(
  axios: AxiosInstance,
  username: string | null,
  examinee_username: string | null,
  token: string
) {
  // リフレッシュトークン API
  return axios.post("/api/refresh_token/", {
    username: username,
    examinee_username,
    token: token,
  });
}

export function signOut(signOutTo?: string) {
  CognitoAuthenticationResultStore.removeItem();
  CognitoChallengeParametersStore.removeItem();
  CognitoRefreshTokenStore.removeItem();
  CognitoSession.removeItem();
  SleepCheckupUserStore.removeItem();
  if (signOutTo != null) {
    window.location.href = signOutTo;
  }
}

export function callConfirmForgotPasswordAPI(
  axios: AxiosInstance,
  username: string,
  verificationCode: string,
  newPassword: string
) {
  // パスワード再設定　API
  return axios.post("/api/confirm_forget_password/", {
    username: username,
    password: newPassword,
    confirmation_code: verificationCode,
  });
}

export function callSignUpAPI(
  axios: AxiosInstance,
  username: string,
  password: string,
  unique_id: string
): Promise<CognitoSignUpResult> {
  // 受診者ユーザーのsign up API
  return axios.post("/api/signup/", {
    username: username,
    password: password,
    unique_id: unique_id,
  });
}

export function callResendConfirmationCodeAPI(
  axios: AxiosInstance,
  email: string
) {
  // 確認コード再送 API
  return axios.post("/api/resend_confirmation_code/", {
    username: email,
  });
}

export function callConfirmSignUpAPI(
  axios: AxiosInstance,
  username: string,
  confirmation_code: string
): Promise<CognitoSignUpResult> {
  // 受診者ユーザーのconfirm_signup API
  return axios.post("/api/confirm_signup/", {
    username: username,
    confirmation_code: confirmation_code,
  });
}
