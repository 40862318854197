import { InitiateAuthResult } from "@/sleep_compass_lite/data_transfer_objects/target/InitiateAuthResult";
import { RegistrationEntry } from "@/sleep_compass_lite/data_transfer_objects/target/RegistrationEntry";
import { ApiError } from "@/sleep_compass_lite/domain_models/target/ApiError";
import { EmailAddress } from "@/sleep_compass_lite/domain_models/target/EmailAddress";
import { Password } from "@/sleep_compass_lite/domain_models/target/Password";
import { getApiServerUrl, useAxios } from "@/utils/axios";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";

/**
 * 初回登録におけるサインアップ
 * 対象者登録APIを呼び出すことで、アカウントの仮作成、メールアドレスの検証コードの送信を行う。
 * @param surveyInfo サーベイ情報のUUID
 * @param email 対象者のメールアドレス
 * @param password 対象者のパスワード
 * @returns 対象者登録APIのレスポンス(データ)
 */
export async function signUp(
  surveyInfo: string,
  email: EmailAddress,
  password: Password
): Promise<RegistrationEntry> {
  try {
    const response = await postRegistrationEntry(surveyInfo, email, password);
    return response.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiError(e.response);
    } else {
      throw e;
    }
  }
}

/**
 * 対象者登録APIの呼び出し
 * @param surveyInfo サーベイ情報のUUID
 * @param email 対象者のメールアドレス
 * @param password 対象者のパスワード
 * @returns 対象者登録APIのレスポンス
 */
function postRegistrationEntry(
  surveyInfo: string,
  email: EmailAddress,
  password: Password
): Promise<AxiosResponse<RegistrationEntry>> {
  return axios.post<RegistrationEntry>(
    `${getApiServerUrl()}/api/lite/registration_entry/`,
    {
      survey_info: surveyInfo,
      email: email.value,
      password: password.value,
    }
  );
}

export interface URLRegistrationEntryPostParameter {
  email: string;
  password: string;
  url_id: string;
}

export interface URLRegistrationEntryPostResult {
  id: string;
}

export interface URLRegistrationEntryPutParameter {
  cognito_verification_code: string;
  url_id: string;
  email: string;
  password: string;
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  birthday: string;
  employee_id?: string; // optional 社員番号
  insurance_symbol?: string; // optional 保険証の記号
  insurance_number?: string; // optional 保険証の番号
}

export interface URLRegistrationEntryPutResult extends InitiateAuthResult {
  id: string;
  survey_info: string;
}

export interface URLRegistrationEntryPatchParameter {
  url_id: string;
}
export interface URLRegistrationEntryPatchResult {
  id: string;
  url_id: string;
}

export function useURLRegistrationEntry() {
  const axios = useAxios();
  const URL = "/api/lite/url_registration_entry/";
  return useMemo(
    () => ({
      /**
       * 新規にアカウントを作成して測定を開始するためのAPI。
       * @param param {@link URLRegistrationEntryPostParameter}
       * @see https://www.notion.so/accelstars/Lite-URL-API-539c9b6190e3406abfb77288ba1e365d?pvs=4#89cad8bce0fd46a5854b8a6470f25de1
       */
      async post(param: URLRegistrationEntryPostParameter) {
        return await axios.post<URLRegistrationEntryPostResult>(URL, param);
      },

      /**
       * 新規にアカウントを作成して測定を開始するためのAPI。
       * @param registrationEntryId
       * @param param {@link URLRegistrationEntryPutParameter}
       * @see https://www.notion.so/accelstars/Lite-URL-API-539c9b6190e3406abfb77288ba1e365d?pvs=4#89cad8bce0fd46a5854b8a6470f25de1
       */
      async put(
        registrationEntryId: string,
        param: URLRegistrationEntryPutParameter
      ) {
        return await axios.put<URLRegistrationEntryPutResult>(
          `${URL}${registrationEntryId}/`,
          param
        );
      },

      /**
       * 新規にアカウントを作成して測定を開始するためのAPI。
       * @param registrationEntryId
       * @param param {@link URLRegistrationEntryPatchParameter}
       * @see https://www.notion.so/accelstars/Lite-URL-API-539c9b6190e3406abfb77288ba1e365d?pvs=4#89cad8bce0fd46a5854b8a6470f25de1
       */
      async patch(
        registrationEntryId: string,
        param: URLRegistrationEntryPatchParameter
      ) {
        return await axios.patch<URLRegistrationEntryPatchResult>(
          `${URL}${registrationEntryId}/`,
          param
        );
      },
    }),
    [axios]
  );
}
