import { Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import { FocusEvent, useEffect, useState } from "react";
import { PASSWORD_SYMBOL_REGEX } from "../activation/Constants";

export type Validator = (value: string) => string;
export type ValidatableTextFieldProps = TextFieldProps & {
  validators?: Validator[];
  onChangeErrorMessage?: (message: string) => void;
};
export function ValidatableTextField({
  validators = [],
  onChangeErrorMessage,
  helperText: orgHelperText,
  onChange: orgOnChange,
  sx,
  ...props
}: ValidatableTextFieldProps) {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  useEffect(() => {
    if (errorMessage != null) {
      onChangeErrorMessage?.(errorMessage);
    }
  }, [errorMessage, onChangeErrorMessage]);

  const onChange = (event: FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const messages = validators
      .map((validator) => validator(value))
      .filter((message) => message !== "");
    setErrorMessage(messages[0] ?? "");
    orgOnChange?.(event);
  };

  const isError = errorMessage != null && errorMessage !== "";

  return (
    <Stack spacing={1} sx={{ width: "100%", ...sx }}>
      <TextField
        error={isError}
        onChange={onChange}
        sx={{ mb: "3px" }}
        {...props}
      />
      {errorMessage && (
        <Typography
          variant="caption"
          sx={{
            px: 3,
            color: "error.main",
          }}
        >
          {errorMessage}
        </Typography>
      )}
      {orgHelperText && (
        <Typography
          variant="caption"
          sx={{
            px: 3,
            color: "text.secondary",
          }}
        >
          {orgHelperText}
        </Typography>
      )}
    </Stack>
  );
}

export const USERNAME_VALIDATORS = [
  (value: string) => (value === "" ? "必須項目です。" : ""),
  (value: string) =>
    !value.match(/^[\w.+\-_]+$/g) ? "使用できない文字列が含まれています。" : "",
];
export const LOGIN_PASSWORD_VALIDATORS = [
  (value: string) => (value === "" ? "必須項目です。" : ""),
  (value: string) =>
    value.replace(PASSWORD_SYMBOL_REGEX, "").replace(/[a-zA-Z\d]/g, "")
      ? "使用できない文字列が含まれています。"
      : "",
];
export const NEW_PASSWORD_WITHOUT_CHECKLIST_VALIDATORS = [
  (value: string) => (value === "" ? "必須項目です。" : ""),
  (value: string) =>
    value.replace(PASSWORD_SYMBOL_REGEX, "").replace(/[a-zA-Z\d]/g, "")
      ? "使用できない文字列が含まれています。"
      : "",
];
export const NEW_PASSWORD_CONFIRM_VALIDATORS = [
  (value: string) =>
    value === ""
      ? "入力は必須です。確認のため同じパスワードを入力してください。"
      : "",
  (value: string) => (value.length < 8 ? "8文字以上の長さにしてください" : ""),
  (value: string) =>
    !(value.match(/[a-z]/) && value.match(/[A-Z]/))
      ? "大文字と小文字の半角英字を含めてください"
      : "",
  (value: string) => (!value.match(/\d/) ? "半角数字を含めてください" : ""),
  (value: string) =>
    !value.match(PASSWORD_SYMBOL_REGEX) ? "記号を含めてください" : "",
  (value: string) =>
    value.replace(PASSWORD_SYMBOL_REGEX, "").replace(/[a-zA-Z\d]/g, "")
      ? "使用できない文字列が含まれています。"
      : "",
];

export const CONFIRMATION_CODE_NUMBER_VALIDATORS = [
  (value: string) => (value === "" ? "必須項目です。" : ""),
  (value: string) =>
    !value.match(/^([0-9]{6})$/) ? "半角数字6桁で入力してください。" : "",
];
