import {
  CognitoAuthenticationResult,
  CognitoChallengeParameters,
} from "@/utils/auth";
import { useAxios } from "@/utils/axios";
import { useMemo } from "react";

export interface URLSurveyEntryPostParameter {
  url_id: string;
  email: string;
  password: string;
}

export interface URLSurveyEntryPostResult {
  survey_info: string;
  AuthenticationResult: CognitoAuthenticationResult;
  ChallengeParameters: CognitoChallengeParameters;
}

export function useURLSurveyEntry() {
  const axios = useAxios();
  const URL = "/api/lite/url_survey_entry/";
  return useMemo(
    () => ({
      async post(param: URLSurveyEntryPostParameter) {
        return axios.post<URLSurveyEntryPostResult>(URL, param);
      },
    }),
    [axios]
  );
}
